import styled from 'styled-components/macro';

import { ReactComponent as DateIcon } from '../../../assets/icons/Date.svg';
import { FormGroup, FormInputStyled, FormInputWrapperStyled } from '../form.styled';


export const DateRangeGroupStyled = FormGroup;

export const DateRangeStyled = FormInputStyled;

export const ModalBackgroundOverlay = styled.div`
  bottom: 0;
  display: ${({ show }) => show ? 'block' : 'none'};
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
`;

export const DateRangeModal = styled.div`
  background-color: ${({ theme }) => theme.components.input.backgroundColor};
  border-radius: ${({ theme }) => theme.components.input.borderRadius};
  display: ${({ show }) => show ? 'block' : 'none'};

  margin-bottom: 8px;
  ${({ position }) => position === 'bottom' ? 'top: 60px' : 'bottom: 60px'};
  overflow: hidden;
  position: absolute;
  z-index: 99;

  .rdrDay, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    color: ${({ theme }) => theme.colors.lightGrey} !important;
  }

  .rdrDayNumber {
    font-weight: 400;

  }
  .rdrDayToday .rdrDayNumber span:after {
    background-color: ${({ theme }) => theme.colors.brand};
  }

  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview, .rdrDateDisplayItemActive {
    border-color: ${({ theme }) => theme.colors.brand} !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: ${({ theme }) => theme.colors.darkText};
  }
`;

export const DateRangeWrapperStyled = FormInputWrapperStyled;

export const CalendarIcon = styled (DateIcon)`
  left: 10px;
  padding: 6px 0;
  position: absolute;
  z-index: 1;
`;
