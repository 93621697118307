/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',
  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      children: [
        {
          path: ['', '/dashboard'],
          load: () => import (/* webpackChunkName: 'dashboard' */ './dashboard'),
        },
      ],
    },
    {
      path: '/signin',
      load: () => import (/* webpackChunkName: 'signin' */ './auth/signin'),
    },
    {
      path: '/reset-password',
      load: () => import (/* webpackChunkName: 'reset-password' */ './auth/reset-password'),
    },
    {
      path: '/forgot-password',
      load: () => import (/* webpackChunkName: 'forgot-password' */ './auth/forgot-password'),
    },
    {
      path: '/new-password',
      load: () => import (/* webpackChunkName: 'new-password' */ './auth/new-password'),
    },
    {
      path: '/tenants',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'tenants-list' */ './tenants/tenants-list'),
        },
        {
          path: '/:tenantId',
          load: () => import (/* webpackChunkName: 'tenant-details' */ './tenants/tenant-details'),
        },
        {
          path: '/edit/:tenantId',
          load: () => import (/* webpackChunkName: 'edit-tenant' */ './tenants/edit-tenant'),
        },
        {
          path: '/:tenantId/users',
          load: () => import (/* webpackChunkName: 'users-list' */ './users/users-list'),
        },
        {
          path: '/:tenantId/cases',
          load: () => import (/* webpackChunkName: 'cases-list' */ './cases/cases-list'),
        },
      ],
    },
    {
      path: '/users',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'users-list' */ './users/users-list'),
        },
        {
          path: '/invite',
          load: () => import (/* webpackChunkName: 'create-user' */ './users/create-user'),
        },
        {
          path: '/:userId',
          load: () => import (/* webpackChunkName: 'user-details' */ './users/user-details'),
        },
        {
          path: '/edit/:userId',
          load: () => import (/* webpackChunkName: 'edit-user' */ './users/edit-user'),
        },
      ],
    },
    {
      path: '/tenants',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'tenants-list' */ './tenants/tenants-list'),
        },
      ],
    },
    {
      path: '/cases',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'cases-list' */ './cases/cases-list'),
        },
        {
          path: '/create',
          load: () => import (/* webpackChunkName: 'create-case' */ './cases/create-case'),
        },
        {
          path: '/partial/create',
          load: () => import (/* webpackChunkName: 'create-case' */ './cases/create-case/PartialCase'),
        },
        {
          path: '/my-exports',
          load: () => import (/* webpackChunkName: 'cases-export' */ './cases/cases-export'),
        },
        {
          path: '/:caseId',
          load: () => import (/* webpackChunkName: 'case-details' */ './cases/case-details'),
        },
        {
          path: '/edit/:caseId',
          load: () => import (/* webpackChunkName: 'edit-case' */ './cases/edit-case'),
        },
        {
          path: '/edit/partial/:caseId',
          load: () => import (/* webpackChunkName: 'edit-case' */ './cases/edit-case/PartialCase'),
        },
      ],
    },
    {
      path: '/insurances',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'insurances-list' */ './insurances/insurances-list'),
        },
        {
          path: '/create',
          load: () => import (/* webpackChunkName: 'create-insurance' */ './insurances/create-insurance'),
        },
        {
          path: '/:insuranceId',
          load: () => import (/* webpackChunkName: 'insurance-details' */ './insurances/insurance-details'),
        },
        {
          path: '/edit/:insuranceId',
          load: () => import (/* webpackChunkName: 'edit-insurance' */ './insurances/edit-insurance'),
        },
      ],
    },
    {
      path: '/sites',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'sites-list' */ './sites/sites-list'),
        },
        {
          path: '/create',
          load: () => import (/* webpackChunkName: 'create-site' */ './sites/create-site'),
        },
        {
          path: '/:siteId',
          load: () => import (/* webpackChunkName: 'site-details' */ './sites/site-details'),
        },
        {
          path: '/edit/:siteId',
          load: () => import (/* webpackChunkName: 'edit-site' */ './sites/edit-site'),
        },
      ],
    },
    {
      path: '/patients',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'patients-list' */ './patients/patients-list'),
        },
        {
          path: '/create',
          load: () => import (/* webpackChunkName: 'create-patient' */ './patients/create-patient'),
        },
        {
          path: '/invite',
          load: () => import (/* webpackChunkName: 'invite-patient' */ './patients/invite-patient'),
        },
        {
          path: '/:patientId',
          load: () => import (/* webpackChunkName: 'patient-details' */ './patients/patient-details'),
        },
        {
          path: '/edit/:patientId',
          load: () => import (/* webpackChunkName: 'edit-patient' */ './patients/edit-patient'),
        },
      ],
    },
    {
      path: '/settings',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'settings' */ './settings/settings'),
        },
        {
          path: '/tenant',
          load: () => import (/* webpackChunkName: 'tenant-settings' */ './settings/tenant-settings'),
        },
      ],
    },
    {
      path: '/user-settings',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'user-details' */ './user-settings/user-details'),
        },
        {
          path: '/edit/:userId',
          load: () => import (/* webpackChunkName: 'edit-user' */ './user-settings/edit-user'),
        },
      ],
    },
    {
      path: '/providers',
      children: [
        {
          path: '',
          load: () => import (/* webpackChunkName: 'providers-list' */ './surgeons/surgeons-list'),
        },
        {
          path: '/create',
          load: () => import (/* webpackChunkName: 'create-provider' */ './surgeons/create-surgeon'),
        },
        {
          path: '/:providerId',
          load: () => import (/* webpackChunkName: 'provider-details' */ './surgeons/surgeon-details'),
        },
        {
          path: '/edit/:providerId',
          load: () => import (/* webpackChunkName: 'edit-provider' */ './surgeons/edit-surgeon'),
        },
      ],
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import (/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action ({ next }) {
    // Execute each child route until one of them return the result
    const route = await next ();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - Clomr`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (process.env.REACT_APP___DEV__) {
  routes.children.unshift ({ // eslint-disable-line no-restricted-properties
    path: '/error',
    action: require ('./error').default,
  });
}

export default routes;
