/**
 * Merge and style the components based on the component style in the main theme
 * And the variants of this component.
 * @param {string} componentName The key of the component as it's in the main theme object
 * @param {object} theme The main theme object
 * @param {string} variant The name of the applied variant
 * @param {object} defaultTheme The default theme
 * @returns The merged style object
 */
export const getComponentStyle = (componentName, theme = {}, variant = '', defaultTheme = {}) => {
  const basicStyle = theme.components[componentName];
  const variantStyle =
    Array.isArray (variant)
    ? mergeVariantStyle (componentName, theme, variant)
    : theme.components[componentName]?.variants?.[variant];
  const style = variant && variantStyle
    ? { ...defaultTheme, ...basicStyle, ...variantStyle }
    : { ...defaultTheme, ...basicStyle };
  return style;
};

const mergeVariantStyle = (componentName, theme, variant = []) => {
  const style = variant.reduce ((acc, current) => {
    const currentThemeStyle = theme.components[componentName]?.variants?.[current] || {};

    return {
      ...acc,
      ...currentThemeStyle,
    };
  }, {});

  return style;
};
