import { rgba } from 'polished';
import styled, { css } from 'styled-components/macro';


export const TableSearchRowStyled = styled.div`
  span {
    display: flex;
    width: 100%;
    input {
      flex: 1;
    }
  }
`;

export const TableRowStyled = styled.tr`
${({ clickable, theme }) => clickable && `
  cursor: pointer;
  &:hover {
    background: ${rgba (theme.colors.brand, 0.8)};
  }
`}
`;

export const TableStyled = styled.div`
  width: 100%;

  table {
    border-radius: ${({ theme }) => theme.components.table.borderRadius};
    border-spacing: 0;
    box-shadow: ${({ theme }) => theme.components.table.boxShadow};
    padding: ${({ theme }) => theme.components.table.padding};
    width: 100%;
    thead {
      th {
        text-align: left;
        color: ${({ theme }) => theme.colors.Bluewood};
        font-weight: 700;
        line-height: 12px;
        font-size: 12px;
      }
    }
    tbody {
      th,
      td {
        border-bottom: ${({ theme }) => theme.components.table.td.borderBottom};
        margin: 0;
        padding: 0.75rem 0;
        line-height: 15px;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.dark};

        :last-child {
          border-right: 0;
        }
      }
      tr {
        :first-child {
            height: 20px;
        }
        :last-child {
          th,td {
            border-bottom: 0;
          }
        }
        :focus {
          outline: none;
        }
      }
    }
  }

  ${({ theme }) => css`
  @media ${theme.device.wide} {
    table {
        thead {
          th {
            font-weight: 600;
            line-height: 15px;
            font-size: 15px;
          }
        }
        tbody {
          th,
          td {
            line-height: 18px;
            font-size: 15px;
          }
         }
    }
  `}
`;

export const FiltersStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
