import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Alert, SuccessMessage } from '../../../components';
import { baseStyle, activeStyle, acceptStyle, rejectStyle, Wrapper } from './dropzone.styled';

const Dropzone = ({
  className = 'dropzone',
  getFile,
  accept = '',
  maxFiles = 1,
  message,
  successMessage,
  errorMessage,
  ...rest
}) => {
  const [file, setFile] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
    getFile(file);
  }, [file, getFile]);

  useEffect(() => {
    if(successMessage || errorMessage){
      setFile (null);
    }
  }, [successMessage, errorMessage]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: accept,
    maxFiles: maxFiles,
    onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <React.Fragment>
      <Wrapper>
        {errorMessage && <Alert>{errorMessage}</Alert>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        <div {...getRootProps({ className: className, style })}>
          <input {...getInputProps()} />
          <p>{message}</p>
        </div>
      </Wrapper>
    </React.Fragment>
  )
};

export default Dropzone;
