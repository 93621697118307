import styled from 'styled-components/macro';
import { rgba, darken } from 'polished';


export const AlertStyled = styled.div`
background-color: ${({ theme }) => rgba (theme.colors.error, 0.2)};
border: ${({ theme }) => theme.metrics.borderWidth}px solid ${({ theme }) => theme.colors.error};
border-radius: ${({ theme }) => theme.metrics.inputRadius};
color: ${({ theme }) => darken (0.5) (theme.colors.error)};
font-weight: 500;
padding: 1rem 1.5rem;
`;
