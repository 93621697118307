import styled, { css } from 'styled-components/macro';


export const FormStyled = styled.form`
  background-color: ${({ theme }) => theme.components.form.backgroundColor};
  border-radius: ${({ theme }) => theme.components.form.borderRadius};
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => css`
  @media ${theme.device.tablet} {
    padding: ${theme.components.form.paddingY}px 0px;
  }
`}
  padding: ${({ theme }) => theme.components.form.paddingY}px ${({ theme }) => theme.components.form.paddingX}px;
  /* stylelint-disable-next-line */
  ${({ inline }) => inline ? `
    align-items: flex-end;

    ${FormGroup} {
      position: relative;
    }

    ${FormGroup}:not(:last-of-type) {
      margin-right: 1rem;
    }

    ${ErrorStyled} {
      margin-top: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  ` : `
    align-items: stretch;
  `}
`;

export const FormGroup = styled.div`
  display: ${({ hidden }) => hidden ? 'none' : 'flex'};
  flex: 1;
  flex-basis: 48%;
  flex-direction: column;
  ${({ theme }) => css`
  @media ${theme.device.tablet} {
    flex-basis: 100%;
    padding-right: 0;
  }
`}
  padding-right: 2%;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.components.form.group.marginBottom};
  }
`;

export const FormInputWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const FormInputIcon = (SvgComponent = 'svg', attrs = {}) => styled (SvgComponent).attrs (attrs)`
  ${({ right, theme }) => right ? `
    right: ${theme.components.input.paddingX}px;
  ` : `
    left: ${theme.components.input.paddingX}px;
  `}
  ${({ theme, stroke }) => Boolean (stroke) && `
    &, path {
      stroke: ${theme.colors[stroke] ? theme.colors[stroke] : theme.components.input.iconColor};
    }
  `}

  position: absolute;
  width: ${({ theme }) => theme.components.input.iconWidth}px;
  &, path {
    fill: ${({ theme, color }) => theme.colors[color] ? theme.colors[color] : theme.components.input.iconColor};
  }
`;

export const inputRootStyle = css`
  background-color: ${({ theme }) => theme.components.input.backgroundColor};
  border: ${({ theme }) => `${theme.components.input.borderWidth}px solid ${theme.components.input.borderColor}`};
  border-radius: ${({ theme }) => theme.components.input.borderRadius};
  color: ${({ theme }) => theme.components.input.fontColor};
  display: block;
  font-size: ${({ theme }) => theme.components.input.fontSize};
  font-weight: ${({ theme }) => theme.components.input.fontWeight};

  padding: ${({ theme }) => theme.components.input.paddingY}px ${({ theme, withIcon }) => withIcon ? theme.components.input.iconWidth + theme.components.input.paddingX * 2 - theme.components.input.borderWidth : theme.components.input.paddingX}px;
  transition: ${({ theme }) => theme.components.input.transition};
  width: fill-available;

  /* stylelint-disable-next-line */
  ${({ theme, size, withIcon }) => Boolean (size) && `
    font-size: ${theme.components.input[size].fontSize};
    padding: ${theme.components.input[size].paddingY}px ${(withIcon ? theme.components.input[size].iconWidth + theme.components.input[size].paddingX * 2 - theme.components.input[size].borderWidth : theme.components.input[size].paddingX)}px;
  `}
`;

export const inputInteractiveStyle = css`
  ${({ invalid, theme }) => invalid && `
    border-color: ${theme.colors.error};
  `}

  ${({ valid, theme }) => valid && `
    border-color: ${theme.components.input.successColor};
  `}

  ${({ valid, invalid, theme }) => (invalid || valid) && `
    background-color: ${theme.components.input.backgroundColorFocus};
    outline: none;
    transition: ${theme.components.input.transition};
  `}
`;

export const inputDisabledStyle = css`
  &:disabled {
    background-color: ${({ theme }) => theme.components.input.backgroundColorDisabled};
    opacity: 1;
  }
`;

export const inputFocusStyle = css`
  background-color: ${({ theme }) => theme.components.input.backgroundColorFocus};
  outline: none;
  transition: ${({ theme }) => theme.components.input.transition};
  &:not(:disabled) {
    border-color: ${({ theme }) => theme.components.input.borderColorFocus};
  }
`;

export const inputHoverStyle = css`
  &:hover, &:focus {
    ${inputFocusStyle}
  }
`;

export const FormInputStyled = styled.input`
  ${inputRootStyle}
  ${inputInteractiveStyle}
  ${inputHoverStyle}
  ${inputDisabledStyle}
`;

export const LabelStyled = styled.label`
  color: ${({ theme }) => theme.components.input.label.fontColor};
  font-family: ${({ theme }) => theme.components.input.label.fontFamily};
  font-size: ${({ theme }) => theme.components.input.label.fontSize};
  font-weight: ${({ theme }) => theme.components.input.label.fontWeight};
  margin: ${({ theme }) => theme.components.input.label.margin};
  text-transform: ${({ theme }) => theme.components.input.label.textTransform};
`;

export const ErrorStyled = styled.div`
  color: ${({ theme }) => theme.components.input.errorMessage.fontColor};
  font-family: ${({ theme }) => theme.components.input.errorMessage.fontFamily};
  font-size: ${({ theme }) => theme.components.input.errorMessage.fontSize};
  font-weight: ${({ theme }) => theme.components.input.errorMessage.fontWeight};
  margin: ${({ theme }) => theme.components.input.errorMessage.margin};
  text-transform: ${({ theme }) => theme.components.input.errorMessage.textTransform};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
