import React, { useMemo, useContext } from 'react';

import { UserContext } from '../../context/user-context';
import history from '../../history';
import { SignOutButton } from '../auth';
import { DropdownMenu, MenuItem } from '../dropdown-menu';
import Container from '../layouts/app-layout/Container';

import { NavbarStyled, Title } from './navbar.styled';


const Navbar = ({ userGroups, userName }) => {
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const pathname = history?.location?.pathname;
  const page = useMemo (() => getPageTitle (pathname), [pathname]);

  return (
    <NavbarStyled>
      <Container display="flex" justifyContent="space-between" alignItems="center" flexDirection={['column', 'row']} >
        <Title>{page}</Title>
        <DropdownMenu group={userGroups[0]} name={userName} avatar={user?.avatarUrl || null}>
          <MenuItem><SignOutButton /></MenuItem>
        </DropdownMenu>
      </Container>
    </NavbarStyled>
  );
};


const getPageTitle = (url = '') => {
  if (url === '/') {
    return 'Dashboard';
  } else {
    const pageTitle = url.replace ('/', '').replace (/\/.*/, '');
    return pageTitle.charAt (0).toUpperCase () + pageTitle.slice (1);
  }
};

export default Navbar;
