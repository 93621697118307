import styled from 'styled-components/macro';

import { ReactComponent as DropdownIcon } from '../../assets/icons/Dropdown.svg';
import { ReactComponent as AvatarIcon } from '../../assets/images/avatar-placeholer-icon.svg';


export const NavStyled = styled.div`
  min-width: 400px;
  position: relative;
`;

export const NavHeader = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(229,233,234,0.45);  justify-content: flex-end;
`;

export const Dropdown = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba(229,233,234,0.45);
  margin-top: 10px;
  position: absolute;
  transition: opacity 75ms linear,transform 38ms ease-out;
  z-index: 3;
  width: 100%;
`;

export const Icon = styled (DropdownIcon)`
  margin-left: 10px;
  transform: ${({ open }) => open ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const PleaceholderIcon = styled (AvatarIcon)`
  width: 50px;
  height: 50px;
`;

export const Avatar = styled.div`
  display: inline-block; 
  position: relative; 
  width: 50px; 
  height: 50px; 
  overflow: hidden; 
  border-radius: 50%; 
`;

export const AvatarImage = styled.img`

  width: 100%; height: auto;
`;

export const Item = styled.div`
  text-align: left;
`;

export const UserName = styled.div`
text-align: left;
font-weight: 500;
`;

export const UserGroup = styled.div`
  text-align: left;
`;

export const UserInfo = styled.div`
  display: flex;
  justify-items: center;
  align-items: baseline;
  margin-left: 10px;
`;
