import { map, zipObj } from 'ramda';
import initSqlJs from 'sql.js-fts5';

import { IndexedDB } from './indexed-db';


export const CPTS_OFFLINE_DB = {
  name: 'cpts-offline-db',
  objectStoreName: 'cpts',
  dataId: 2,
};

export default class CPTsOfflineDB {
  static instance;
  constructor (dbFile) {
    if (CPTsOfflineDB.instance) {
      return CPTsOfflineDB.instance;
    }
    CPTsOfflineDB.instance = this;
    this.dbFile = dbFile;
    this.db = null;
    this.dbName = CPTS_OFFLINE_DB.name;
    this.dbObjectKey = CPTS_OFFLINE_DB.objectStoreName;
    this.dbDataId = CPTS_OFFLINE_DB.dataId;
  }

  openDatabase = async () => {
    const SQL = await initSqlJs ({
      locateFile: file => `/${file}`,
    });
    const idb = new IndexedDB (this.dbName, this.dbObjectKey);

    const localDb = await idb.get (this.dbDataId);

    if (localDb) {
      this.dbFile = localDb.value;
    } else if (this.dbFile) {
      idb.set ({
        id: this.dbDataId,
        value: this.dbFile,
      });
    }

    const Uints = new Uint8Array (this.dbFile);
    this.db = new SQL.Database (Uints);
  }

  runSQL (sql, params = []) {
    return new Promise ((resolve, reject) => {
      try {
        const _resultSet = this.db.exec (sql, params);
        const resultSet = _resultSet?.[0] ?? {};
        const { columns = {}, values = [] } = resultSet;
        const results = map (item => zipObj (columns, item)) (values);
        resolve (results);
      } catch (err) {
        reject (err);
      }
    });
  }

  closeDatabase () {
    if (this.db) {
      this.db.close ();
    }
  }
}
