import React, { useMemo } from 'react';

import { not } from 'ramda';

import Ellipsis from './Ellipsis';
import Spinner from './Spinner';


const loaderComponent = type => {
  switch (type) {
    case 'ellipsis':
      return Ellipsis;
    default:
      return Spinner;
  }
};

export const Loader = ({
  type,
  size,
  dotSize,
  borderSize,
  left,
  top,
  margin,
  color,
  show,
  className,
}) => {
  const LoaderComponent = useMemo (() => loaderComponent (type), [type]);
  if (not (show)) {
    return (<></>);
  }
  return (
    <LoaderComponent
      className={className}
      $size={size}
      $color={color}
      $dotSize={dotSize}
      $borderSize={borderSize}
      $left={left}
      $top={top}
      $margin={margin}
    />
  );
};
