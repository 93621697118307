import styled, { css } from 'styled-components/macro';


export const CardStyled = styled.div`
  display: block;
  ${({ grey, theme, mb, borderRadius, padding, clickable }) =>
    grey ?
      css`
        background-color: ${theme.components.greyCard.backgroundColor};
        border-radius: ${borderRadius || theme.components.greyCard.borderRadius};
        box-shadow: ${theme.components.greyCard.boxShadow};
        padding: ${theme.components.greyCard.padding};
        margin-bottom: ${mb || theme.components.greyCard.marginBottom};
        cursor: ${clickable ? 'pointer' : 'inherit'};
      ` : css`
        background-color: ${theme.components.whiteCard.backgroundColor};
        border-radius: ${borderRadius || theme.components.whiteCard.borderRadius};
        box-shadow: ${theme.components.whiteCard.boxShadow};
        padding: ${padding || theme.components.whiteCard.padding};
        margin-bottom: ${mb || theme.components.whiteCard.marginBottom};
        cursor: ${clickable ? 'pointer' : 'inherit'};
      `}
`;
