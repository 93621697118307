import styled, { css } from 'styled-components/macro';

import { Link } from '../../history';


export const SidebarStyled = styled.aside`
  align-items: flex-start;
  min-height: 100vh;
  ${({ theme, active }) => css`
  background-color: ${theme.colors.dark};
  @media ${theme.device.mobile} {
    display: ${active ? 'block' : 'none'};
    position: absolute;
    z-index: 100;
    right: 0;
    width: 80%;
    transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  }
  ul {
    list-style: none;
    margin: 30px 0 0;
    padding: 0;
    width: 100%;
  }
`}
  padding-top: 50px;
  text-align: left;
`;

export const SidebarLink = styled (Link)`
  color: ${({ theme }) => theme.colors.lightGrey};
  display: block;
  svg {
    height: 24px;

    &, g, path {
      fill: ${({ active, theme }) => active === 'true' ? theme.colors.brand : theme.colors.lightGrey};
    }
  }

  /* stylelint-disable-next-line */
  ${({ active, theme }) => active === 'true' && `
    color: ${theme.colors.brand};
    font-weight: 500;
  `}

  padding: 1em;
  text-decoration: none;
  transition: .2s ease background-color, .2s ease color;

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
    transition: .2s ease background-color, .2s ease color;

    svg, svg g, svg path {
      fill: ${({ theme }) => theme.colors.brand};
    }
  }
`;

export const IconWrapper = styled.span`
  display: inline-block;
  margin-right: 10px;
  width: 40px;
`;

export const Logo = styled.img`
  margin-left: 10px;
  margin-right: 10px;
  width: 50px;
`;

export const LogoLink = styled (Link)`
  color: ${({ theme }) => theme.colors.brand};
  font-size: 30px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }
`;

export const MenuIcon = styled.div`
  cursor: pointer;
  display: none;
  ${({ theme }) => css`
  @media ${theme.device.mobile} {
    display: block;
    margin: 0 20px 25px;
  }
`}
`;
