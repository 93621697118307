import styled, { css } from 'styled-components';

export const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
`;

export const SpinnerWrapper = styled.div`
  ${({ $size = 40, $color = 'black', $borderSize = 4, $margin = 10 }) => css`
    display: block;
    margin: auto;
    height: ${$size}px;
    position: relative;
    width: ${$size}px;
    div {
      animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border: ${$borderSize}px solid ${$color};
      border-color: ${$color} transparent transparent transparent;
      border-radius: 50%;
      box-sizing: border-box;
      display: block;
      height: ${$size - $margin}px;
      margin: ${$margin}px;
      position: absolute;
      width: ${$size - $margin}px;
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
    /* stylelint-disable-next-line */
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `};
`;

export const EllipsisWrapper = styled.div`
  ${({ $size = 20, $color, $dotSize = 7, $top = 0, $left = -5, theme }) => css`
    display: inline-block;
    height: ${$size}px;
    vertical-align: sub;
    position: relative;
    width: ${$size}px;
    div {
      position: absolute;
      top: ${$top || ($size - $dotSize) / 2}px;
      width: ${$dotSize}px;
      height: ${$dotSize}px;
      border-radius: 50%;
      background: ${$color ? $color : theme.colors.brand};
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
      left: ${$left}px;
      animation: ellipsis1 0.6s infinite;
    }
    div:nth-child(2) {
      left: ${$left + $size / 2}px;
      animation: ellipsis2 0.6s infinite;
    }
    div:nth-child(3) {
      left: ${$left + $size / 2}px;
      animation: ellipsis3 0.6s infinite;
    }
    div:nth-child(4) {
      left: ${$size}px;
      animation: ellipsis4 0.6s infinite;
    }
    @keyframes ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes ellipsis4 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes ellipsis2 {
      0% {
        transform: translate(${$left}px, 0);
      }
      100% {
        transform: translate(${$size / 4}px, 0);
      }
    }
    @keyframes ellipsis3 {
      0% {
        transform: translate(${$size / 4}px, 0);
      }
      100% {
        transform: translate(${$size}px, 0);
      }
    }
  `};
`;

export const Logo = styled.img`
  margin-bottom: 20px;
  width: 80px;
`;
