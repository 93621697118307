export class IndexedDB {
  constructor (name, objectStoreName, version = 1) {
    this.name = name;
    this.objectStoreName = objectStoreName;
    this.version = version;
    this.instance = {};
  }

  upgrade = e => {
    const _db = e.currentTarget.result;
    const names = _db.objectStoreNames;
    const name = this.objectStoreName;
    if (!names.contains (name)) {
      _db.createObjectStore (name, {
        keyPath: 'id',
        autoIncrement: true,
      });
    }
  }

  errorHandler = error => {
    console.error (error); // eslint-disable-line no-console
  }

  open = (callback = () => {}) => {
    const request = window.indexedDB.open (this.name, this.version);

    request.onerror = this.errorHandler;

    request.onupgradeneeded = this.upgrade;

    request.onsuccess = function (e) {
      this.instance = request.result;
      this.instance.onerror = this.errorHandler;
      callback (this.instance);
    };
  }

  getObjectStore = (mode = 'readonly', instance) => {
    const txn = instance.transaction ([this.objectStoreName], mode);

    const store = txn.objectStore (this.objectStoreName);

    return store;
  }

  set = (data, callback = () => {}) => {
    this.open (instance => {
      const store = this.getObjectStore ('readwrite', instance);
      const request = data.id ? store.put (data) : store.add (data);
      request.onsuccess = callback;
    });
  }

  getAll = (callback = () => {}) => {
    this.open (instance => {
      const store = this.getObjectStore ('readonly', instance);
      const cursor = store.openCursor ();
      const data = [];

      cursor.onsuccess = function (e) {
        const result = e.target.result;

        if (result && result != null) {
          // eslint-disable-next-line no-restricted-properties
          data.push (result.value);
          result.continue ();
        } else {
          callback?. (data);
        }
      };
    });
  }

  get = (id, callback) => new Promise (resolve => {
    const _id = parseInt (id, 10);

    this.open (instance => {
      const store = this.getObjectStore ('readonly', instance);
      const request = store.get (_id);

      request.onsuccess = function (e) {
        callback?. (e.target.result);
        resolve (e.target.result);
      };
    });
  });

  remove = (id, callback = () => {}) => {
    const _id = parseInt (id, 10);

    this.open (instance => {
      const mode = 'readwrite';

      const store = this.getObjectStore (mode, instance);
      const request = store.delete (_id);

      request.onsuccess = callback;
    });
  }

  deleteAll = (callback = () => {}) => {
    this.open (instance => {
      const mode = 'readwrite';
      const store = this.getObjectStore (mode, instance);
      const request = store.clear ();

      request.onsuccess = callback;
    });
  }
}
