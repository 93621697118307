import React from 'react';
import styled from 'styled-components/macro';

import { removeEmpty, toLabelValue } from '../../../utils';
import { Select } from '../../forms';


const FilterWrapper = styled.div`
  min-width: 120px;
`;

export function SelectFilter ({
  column: { filterValue, setFilter, preFilteredRows, id, isMulti },
}) {
  const options = React.useMemo (() => {
    const options = new Set ();

    preFilteredRows.forEach (row => options.add (row.values[id]));

    const finalOptions = isMulti ? [...options] : ['All', ...options];

    return toLabelValue (removeEmpty (finalOptions));
  }, [id, preFilteredRows, isMulti]);

  const handleChange = selectedOption => {
    const newOption = isMulti ? selectedOption : selectedOption.label;
    setFilter (newOption !== 'All' ? newOption : undefined); // eslint-disable-line
  };

  return (
    <FilterWrapper>
      <Select
        value={filterValue}
        options={options}
        onChange={handleChange}
        isMulti={isMulti}
        size="small"
        noVerify
        returnFullOption
      />
    </FilterWrapper>
  );
}

export const multiSelectFilter = (rows, id, filterValue) => {
  const filteredRows = rows.filter (row => filterValue?.filter (fValue => fValue.label === row.values[id[0]])?.length > 0);
  return filterValue?.length > 0 ? filteredRows : rows;
};
