import React, { useState } from 'react';
import { add, subtract } from 'ramda';
import { Storage } from 'aws-amplify'
import { Document, Page, pdfjs } from 'react-pdf';

import { FileWrapper, FileBackDrop, CloseButton, Image, PageControls, PDFWrapper, Button, ButtonsWrapper, DownloadButton } from './viewFile.styled';
import { getKeyFromUrl, getFileName } from '../../utils/helpers';

const ViewFile = ({
  show,
  setShow,
  file,
  allowDownload = true,
  type = 'image'
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  const downloadFile = async (url, type) =>  {
    const result = await Storage.get(getKeyFromUrl(url), { download: true });
    downloadBlob(result.Body, getFileName(url, type));
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <>
      <FileWrapper show={show}>
        {setShow &&
          <CloseButton onClick={() => setShow(false)} />}
       {file && allowDownload && <DownloadButton onClick={() => downloadFile(file, `/${type}s/`)}/>}
        {type === 'pdf'
          ? <PDFWrapper>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page pageNumber={pageNumber} />

            </Document>
            {numPages && numPages > 1
              ? <PageControls>
                <ButtonsWrapper>
                  <Button disabled={pageNumber === 1} onClick={() => setPageNumber(subtract(pageNumber)(1))}>{'‹'}</Button>
                  <div>{pageNumber} of {numPages}</div>
                  <Button disabled={pageNumber === numPages} onClick={() => setPageNumber(add(1)(pageNumber))}>{'›'}</Button>
                </ButtonsWrapper>
              </PageControls>
              : <React.Fragment />
            }
          </PDFWrapper>
          : <Image url={file} />
        }
      </FileWrapper>
      {show && setShow &&
        <FileBackDrop onClick={() => setShow(false)} />}
    </>
  )
}
export default ViewFile;
