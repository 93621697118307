/* eslint-disable */
const getAwsConfig = () => {
  return {
    userPoolId: process.env.REACT_APP_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    bucket: process.env.REACT_APP_CASE_FILES_BUCKET,
  }
}

export default getAwsConfig;
