import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';


export const getUserGroup = async () => {
  const user = await Auth.currentAuthenticatedUser ();
  const groups = user?.signInUserSession?.accessToken?.payload?.['cognito:groups'];

  return groups || [];
};

export const hasPermission = async group => {
  const userGroups = await getUserGroup ();

  return R.includes (userGroups) (group);
};

export const isSuperadmin = async () => await hasPermission ('super-admin');

/**
 * New
 */

export const hasAnyGroup = (currentUserGroups, requiredGroups) => R.compose (R.gt (R.__, 0), R.length, R.intersection (currentUserGroups)) (requiredGroups);

export const hasAllGroups = (currentUserGroups, requiredGroups) => R.compose (R.equals (0), R.length, R.difference (currentUserGroups)) (requiredGroups);

export const USER_GROUPS = {
  superAdmin: 'superadmin',
  analyst: 'analyst',
  biller: 'biller',
  officeAdmin: 'officeadmin',
  provider: 'provider',
  userAdmin: 'useradmin',
  globalAdmin: 'globaladmin',
};

export const scopeKeys = {
  view: 'view',
  create: 'create',
  update: 'update',
  delete: 'delete',
  destroy: 'destroy',
};

export const groupsList = Object.values (USER_GROUPS);

export const filterByGroup = (field, userGroups) => {
  // Show for all groups when no group specified
  // OR
  // If userGroups not exists
  if (!field?.groups || !userGroups) {
    return true;
  }

  return hasAnyGroup (userGroups, field.groups);
};

export const filterByTenant = (tenantId, userGroups) => R.filter (item => hasAllGroups (userGroups, [USER_GROUPS.superAdmin]) ? true : item.tenant?.id === tenantId);


export const useAuth = () => {
  const [authState, setAuthState] = useState (AuthState.Loading);
  const [user, setUser] = useState ('');
  useEffect (() => {
    // Define updateAuthState
    const updateAuthState = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser ();
        setUser (user);
        if (user) {
          // No error, change auth state to show page content
          setAuthState (AuthState.SignedIn);
        }
      } catch (error) {
        // Error, change auth state to redirect user to login page
        setAuthState (AuthState.SignedOut);
      }
    };

    // Call AuthState Once
    updateAuthState ();

    // Set up Hub to listen for auth event in case user log out
    Hub.listen ('auth', updateAuthState);
    return () => Hub.remove ('auth', updateAuthState); // cleanup
  }, []);

  return {
    authState,
    user,
  };
};

export const ChallengeNames = {
  SMS_VERIFY: 'SMS_MFA',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
};
