import styled, { css } from 'styled-components/macro';

import { FormGroup, FormInputStyled } from '../form.styled';


export const FileInputGroupStyled = FormGroup;

export const FileInputStyled = styled (FormInputStyled)`
  ${({ theme }) => css`
    @media ${theme.device.mobile} {
      font-size: 0.7rem;
    }
  `}
`;
