import { ReactComponent as DashboardIcon } from '../../assets/icons/Tab_Dashboard_Unselected.svg';
import { ReactComponent as CasesIcon } from '../../assets/icons/Tab_Cases_Unselected.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/Tab_MyGroup_Unselected.svg';
import { ReactComponent as PatientsIcon } from '../../assets/icons/Tab_Patients_Unselected.svg';
import { ReactComponent as SurgeonsIcon } from '../../assets/icons/Tab_Providers_Unselected.svg';
import { ReactComponent as SitesIcon } from '../../assets/icons/Location_Disabled.svg';
import { ReactComponent as InsurancesIcon } from '../../assets/icons/Insurance.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/Settings.svg';

const superAdminNavItems = [
  {
    url: '/',
    text: 'Dashboard',
  },
  {
    url: '/tenants',
    text: 'Tenants',
  },
  {
    url: '/users',
    text: 'Users',
  },
  {
    url: '/insurances',
    text: 'Insurances',
  },
  {
    url: '/sites',
    text: 'Sites',
  },
  {
    url: '/patients',
    text: 'Patients',
  },
  {
    url: '/providers',
    text: 'Providers',
  },
  {
    url: '/user-settings',
    text: 'User Settings',
  },
  
];

const tenantAdminNavItems = [
  {
    url: '/',
    icon: DashboardIcon,
    text: 'Dashboard',
  },
  {
    url: '/users',
    icon: UsersIcon,
    text: 'Users',
  },
  {
    url: '/cases',
    icon: CasesIcon,
    text: 'Cases',
  },
  {
    url: '/patients',
    icon: PatientsIcon,
    text: 'Patients',
  },
  {
    url: '/insurances',
    icon: InsurancesIcon,
    text: 'Insurances',
  },
  {
    url: '/sites',
    icon: SitesIcon,
    text: 'Sites',
  },
  {
    url: '/providers',
    icon: SurgeonsIcon,
    text: 'Providers',
  },
  {
    url: '/settings',
    icon: SettingsIcon,
    text: 'Settings',
  },
  {
    url: '/user-settings',
    icon: UsersIcon,
    text: 'User Settings',
  },
];

const providerNavItems = [
  {
    url: '/',
    icon: DashboardIcon,
    text: 'Dashboard',
  },
  {
    url: '/users',
    icon: UsersIcon,
    text: 'Users',
  },
  {
    url: '/cases',
    icon: CasesIcon,
    text: 'Cases',
  },
  {
    url: '/patients',
    icon: PatientsIcon,
    text: 'Patients',
  },
  {
    url: '/insurances',
    icon: InsurancesIcon,
    text: 'Insurances',
  },
  {
    url: '/sites',
    icon: SitesIcon,
    text: 'Sites',
  },
  {
    url: '/providers',
    icon: SurgeonsIcon,
    text: 'Providers',
  },
  {
    url: '/user-settings',
    icon: UsersIcon,
    text: 'User Settings',
  },
];

const billerNavItems = [
  {
    url: '/',
    icon: DashboardIcon,
    text: 'Home',
  },
  {
    url: '/cases',
    icon: CasesIcon,
    text: 'Cases',
  },
  {
    url: '/patients',
    icon: PatientsIcon,
    text: 'Patients',
  },
  {
    url: '/insurances',
    icon: InsurancesIcon,
    text: 'Insurances',
  },
  {
    url: '/user-settings',
    icon: UsersIcon,
    text: 'User Settings',
  },
];

export const navItems = {
  superadmin: superAdminNavItems,
  globaladmin: tenantAdminNavItems,
  biller: billerNavItems,
  provider: providerNavItems,
};
