import React from 'react';
import moment from 'moment';
import { Box, Flex } from 'reflexbox/styled-components';

import { Button } from '../button';


const options = [
  {
    label: '90 Days',
    value: {
      fromDate () { return moment ().add (-3, 'M').format ('YYYY-MM-DD') },
      toDate () { return  moment ().format ('YYYY-MM-DD') },
    },
  },
  {
    label: 'Last Month',
    value: {
      fromDate () { return moment ().add (-1, 'M').format ('YYYY-MM-DD') },
      toDate () { return  moment ().format ('YYYY-MM-DD') },
    },
  },
  {
    label: 'Last year',
    value: {
      fromDate () { return moment ().add (-12, 'M').format ('YYYY-MM-DD') },
      toDate () { return  moment ().format ('YYYY-MM-DD') },
    },
  },
];

export function DateRangeFilter ({ filterValue, setFilter }) {
  return (
    <div style={{ marginRight: 12 }}>
      <div>Date</div>
      <Flex flexDirection="row" width={'440px'} ml={'-10px'} mb={[12, 12, 12, 12, 12, 1]} flexWrap={['wrap', 'nowrap']} >
        {options.map ((item, i) => (
          <Box width={1 / 3} m={1} key={i}>
            <Button
              width={'130px'}
              active={filterValue === item.value}
              tag="button"
              onClick={() => {
                setFilter (item.value);
              }}
              bordered
              mr={1}>
              {item.label}
            </Button>
          </Box>
        ))}
      </Flex>
    </div>
  );
}

