import styled, { css } from 'styled-components/macro';
import { Box } from 'reflexbox';


export const AppLayoutStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
`;

export const PageContainer = styled (Box)`
  background-color: ${({ theme }) => theme.colors.lightBg};
  border-radius: 20px;
`;
export const OpenMenuButton = styled.div`
    cursor: pointer;
    display: none;
    ${({ theme }) => css`
    @media ${theme.device.mobile} {
      display:block;
    }
  `}
  position: absolute;
  right: 45px;
`;
