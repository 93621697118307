import { gql } from '@apollo/client';


export const caseFragments = gql`
fragment tenant on Tenant {
  id
  name
}

fragment patient on Patient {
  id
  name { use family }
  active
  birthDate
  gender
  telecom { 
    use
    system
    value 
  }
  address { 
    text
    city
    district
    state
    postalCode  
  }
}

fragment user on UserProfile {
  id
  cognitoId
  firstName
  lastName
  credentials
  providerType
  tenant { ...tenant }
}

fragment site on Site {
  id
  name
  fullName
}

fragment insurance on Insurance {
  id
  name
  unitRate
}
`;

export const listCasesQuery = gql`
query listCases($filter: CaseFilterInput, $limit: Int, $skip: Int){
  listCases(filter: $filter, limit: $limit, skip: $skip) {
    limit,
    totalPages,
    cases {
      id
      caseType
      date
      patient { ...patient }
      providers { 
        anesthesiologist { ...user }
        surgeon { ...user }
        assistant { ...user }
      }
      site { ...site }
      insurance { ...insurance }
      startTime
      endTime
      startDate
      endDate
      pdfs
      images
      status
      note
    }
  }
}
${caseFragments}
`;

export const getCaseQuery = gql`
query getCase($id: ObjectID!) {
  getCase(id: $id) {
    id
    caseType
    date
    startDate
    endDate
    patient { ...patient }
    providers { 
      anesthesiologist { ...user }
      surgeon { ...user }
      assistant { ...user }
    }

    site { ...site }
    insurance { ...insurance }
    startTime
    endTime
    pdfsUrls
    pdfs
    images
    imagesUrls
    status
  }
}
${caseFragments}
`;

export const listMessagesQuery = gql`
query listMessages($caseId: ObjectID!) {
  listMessages(caseId: $caseId) {
    id
    createdAt
    owner {
      cognitoId
      firstName
      lastName
    }
    message
  }
}
`;

export const getUserQuery = gql`
  query getUser {
    getAuthenticatedUserDetails {
      id
      firstName
      lastName
      type
      email
      providerType
    }
  }
`;


export const getCaseLineItemsQuery = gql`
  query getCaseLineItems ($id: ObjectID!) {
    getCaseLineItems (id: $id) {
      type
      id
      status
      quantity
      billedAmount
      expectedAmount
      actualAmount
      explanation
      cptItem {
        procedureCode
        procedureDescriptor
        anesthesiaCode
        anesthesiaDescriptor
        type
      }
      modifiers {
        code
        displayName
        modifierType
      }
    }
  }
`;

export const getMyCasesExportQuery = gql`
  query getMyCasesExport($id: ObjectID!) {
    getMyCasesExport(id: $id)  {
      id
      requestedDate
      completedDate
      status
      requestedBy
    }
  }
`;

export const listMyCasesExportQuery = gql`
  query listMyCasesExport {
    listMyCasesExport  {
      id
      requestedDate
      completedDate
      status
      requestedBy
    }
  }
`;

export const getMyCasesExportFileQuery = gql`
  query getMyCasesExportFile($id: ObjectID!) {
    getMyCasesExportFile(id: $id)  {
      fileUrl
    }
  }
`;
