import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { getComponentStyle } from '../helpers';


export const ModalWrapper = styled.div`
  ${({ theme, variant }) => {
    const modalStyle = getComponentStyle ('modal', theme, variant);
    return css`
      background-color: ${modalStyle.backgroundColor};
      border-radius: ${modalStyle.borderRadius};
      box-shadow: ${modalStyle.boxShadow};
      flex-direction: column;
      height: ${modalStyle.height};
      inset: 0;
      margin: auto;
      max-width: 90%;
      position: fixed;
      width: ${modalStyle.width};
      z-index: ${modalStyle.zIndex};
      /* stylelint-disable-next-line */
      ${({ show }) => Boolean (show) ? css`
        display: flex;
      ` : css`
        display: none;
      `}
    `;
  }};
`;

export const ModalHeader = styled.div`
  ${({ theme, variant }) => {
    const modalStyle = getComponentStyle ('modal', theme, variant);

    return css`
      background-color: ${modalStyle.headerBackgroundColor};
      border-radius: ${modalStyle.borderRadius} ${modalStyle.borderRadius} 0 0;
      box-shadow: ${modalStyle.boxShadow};
      font-size: 20px;
      color: ${modalStyle.headerColor};
      padding: ${modalStyle.headerPadding};
    `;
  }};
`;

export const ModalBody = styled.div`
  ${({ theme, variant }) => {
    const modalStyle = getComponentStyle ('modal', theme, variant);

    return css`
      height: 100%;
      padding: ${modalStyle.bodyPadding};
    `;
  }};
`;

export const ModalDescription = styled.div`
  ${({ theme, variant }) => {
    const modalStyle = getComponentStyle ('modal', theme, variant);

    return css`
      color: ${modalStyle.descriptionColor};
      padding: ${modalStyle.descriptionPadding};
      margin: ${modalStyle.descriptionMargin};
      font-size: 20px;
      text-align: center;
    `;
  }};
`;

export const ModalBackDrop = styled.div`
  ${({ theme, variant }) => {
    const modalStyle = getComponentStyle ('modal', theme, variant);

    return css`
      background-color: ${modalStyle.backdropBackgroundColor};
      inset: 0;
      position: fixed;
      z-index: ${modalStyle.zIndex - 1};
    `;
  }};
`;

export const CloseButton = styled.div.attrs ({ children: <CloseIcon /> })`
  background-color: transparent;
  bottom: 0;
  cursor: pointer;
  margin: 0;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 0;
  transition: .3s ease opacity;

  &, svg {
    height: 45px;
  }

  svg {
    stroke: ${({ theme }) => theme.colors.grey};
    width: 15px;
  }

  &:hover {
    background-color: transparent !important;
    opacity: 0.9;
    transition: .3s ease opacity;
  }
`;

export const ModalAlert = styled.div`
  margin: 20px 20px 0;
`;
