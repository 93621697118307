import React, { useMemo, useState } from 'react';
import { Calendar } from 'react-date-range';

import { LabelStyled } from '../form.styled';

import { DateRangeStyled, DateRangeGroupStyled, DateRangeWrapperStyled, DateRangeModal, ModalBackgroundOverlay, CalendarIcon } from './datePicker.styled';


const DatePicker = ({
  name,
  error,
  value,
  size,
  label,
  maxDate,
  minDate,
  position = 'bottom',
  ...rest
}) => {
  const invalid = useMemo (() => Boolean (error), [error]);
  const formattedDate = useMemo (() => value ? new Date (value).toLocaleDateString () : new Date ().toLocaleDateString (), [value]);
  const [showModal, setShowModal] = useState (false);

  const handleChange = selectedDate => {
    if (value?.valueOf () !== selectedDate.valueOf ()) {
      setShowModal (false);
    }

    rest.onChange (selectedDate);
  };
  return (
    <DateRangeGroupStyled invalid={Boolean (error)}>
      <ModalBackgroundOverlay
        show={showModal}
        onClick={() => setShowModal (p => !p)}
      />
      {label &&
        <LabelStyled htmlFor={name}>{label}</LabelStyled>}
      <DateRangeWrapperStyled>
        <CalendarIcon />
        <DateRangeStyled
          name={name}
          invalid={invalid}
          valid={false}
          withIcon={true}
          size={size}
          placeholder={rest.placeholder}
          onClick={() => setShowModal (p => !p)}
          autoComplete="off"
          value={formattedDate}
          onChange={() => null}
        />
        <DateRangeModal show={showModal} position={position}>
          <Calendar
            name={name}
            invalid={invalid}
            withIcon={true}
            editableDateInputs={true}
            maxDate={maxDate}
            minDate={minDate}
            date={value ? new Date (value) : new Date ()}
            {...rest}
            onChange={handleChange}
          />
        </DateRangeModal>
      </DateRangeWrapperStyled>
    </DateRangeGroupStyled>
  );
};

export default DatePicker;
