import { filter } from 'ramda';
import React from 'react';
import { useFilters, useGlobalFilter, useTable } from 'react-table';

import { filterByGroup } from '../../utils/auth';

import { TableRowStyled, TableSearchRowStyled, TableStyled, FiltersStyled } from './table.styled';
import { TableGlobalFilter } from './filters';
import { Loader } from '../loaders';


export const Table = ({
  columns,
  data,
  userGroups,
  withGlobalFilter = true,
  globalFilterHandler,
  onRowClick,
  loading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
  );

  if (loading) {
    return (<Loader show={loading} type='ellipsis' />);
  }
  return (
    <TableStyled>
      {headerGroups.map((headerGroup, idx) => {
        const headers = !userGroups ? headerGroup.headers : filter(header => filterByGroup(header, userGroups))(headerGroup.headers);

        return (
          <FiltersStyled key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headers.map((column, index) => (
              <div key={index} {...column.getHeaderProps()}>
                {column.canFilter && column.Filter ? column.render('Header') : null}
                {/* Render the columns filter UI */}
                <div>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
              </div>
            ))}
          </FiltersStyled>
        );
      })}
      {withGlobalFilter &&
        <TableSearchRowStyled>
          <TableGlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            customHandler={globalFilterHandler}
          />
        </TableSearchRowStyled>}

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx) => {
            const headers = !userGroups ? headerGroup.headers : filter(header => filterByGroup(header, userGroups))(headerGroup.headers);

            return (
              <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
                {headers.map((column, index) => (
                  <th key={index} {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {/* Render the columns filter UI */}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          <TableRowStyled></TableRowStyled>
          {rows.map((row, i) => {
            prepareRow(row);
            const cells = !userGroups ? row.cells : filter(cell => filterByGroup(cell?.column, userGroups))(row.cells);

            return (
              <TableRowStyled key={i} {...row.getRowProps()} clickable={typeof onRowClick === 'function'} onClick={() => onRowClick?.(row.original)}>
                {cells.map((cell, idx) => <td key={idx} {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
              </TableRowStyled>
            );
          })}
        </tbody>
      </table>
    </TableStyled>
  );

};

export default Table;
