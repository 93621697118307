import Papa from 'papaparse';

export const csvToArrayObjects = ( data ) => {

  const headers = data.find((item, index) => index === 0);
  const rows = data.filter((item, index) => index > 0);

  const arr = rows.map (row => {
    const values = row;
    const empty = values.filter (item => item === '');

    const el = headers.reduce ((object, header, index) => {
      object[header] = values[index] ? (values[index]).trim () : values[index];
      return object;
    }, {});

    if (values.length === empty.length) {
      return '';
    } else {
      return el;
    }
  });

  // return the array
  return arr.filter (item => item !== '');
};

export const getBase64 = file => new Promise ((resolve, reject) => {
    const reader = new FileReader ();
    reader.readAsDataURL (file);
    reader.onload = () => resolve (reader.result);
    reader.onerror = error => reject (error);
  });

export const readCSV = (file, callback) => {
  Papa.parse(file, {
    complete: function(results, file) {
      if (callback) callback (csvToArrayObjects (results.data));
    }
  })
};

export const handleAddCSV = (csv, setCSVFile, validateCSV, setError, setValid) => {
  const fileName = csv?.name?.split ('.')[0];
  if (!fileName) {
   return;
  }
  const type = csv.name.split ('.')[1];

  const fileItem = {
    file: csv,
    name: fileName,
    type: type,
    uri: `tmp/${csv.name}`,
  };

  readCSV (fileItem.file, result => {

    let upload = () => {
      setError (null);
      setCSVFile (fileItem);
    };

    let error = false;

    if (result.length > 50) {
      error = true;
      setValid (false);
      setError ("Rows must be less than or equal 50.");
    }

    if(!error) {
      if(validateCSV) {
        validateCSV(result).then(({ valid, message }) => {
          if(valid) {
            setValid (true);
            upload();
          } else {
            setValid (false);
            setError (message);
          }
        })
      } else {
        upload();
      }
    }

  });
};

export async function uploadCSV ({ item, mutation, mutationFormatter, mutationCallback }) {
  try {
    // the CSV content as string
    const reader = new FileReader ();
    reader.onload = function (event) {
      readCSV (item.file, result => {
        mutation ({
          variables: {
            input: mutationFormatter(result),
          },
        }).then(mutationCallback);
      });
    };
    reader.readAsText (item.file);

  } catch (e) {
    // eslint-disable-next-line no-console
    console.log ('Error', e);
  }
}
