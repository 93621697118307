import React from 'react';

import { ErrorStyled, LabelStyled } from '../form.styled';

import { FileInputStyled, FileInputGroupStyled } from './fileInput.styled';


const FileInput = ({
  name,
  label,
  error,
  tag = 'input',
  ...rest
}) => (
    <FileInputGroupStyled invalid={Boolean (error)}>
      {label &&
        <LabelStyled htmlFor={name}>{label}</LabelStyled>}
      <FileInputStyled
        as={tag}
        name={name}
        invalid={Boolean (error)}
        {...rest}
        type="file"
      />
      {error &&
        <ErrorStyled>{error}</ErrorStyled>}
    </FileInputGroupStyled>
  );

export default FileInput;
