import { isNil } from 'ramda';
import { css } from 'styled-components/macro';


const createSpacingFn = parameters => name => props => {
  const multiplier = props[name];
  if (!isNil (multiplier)) {
    const value = props.theme.space[multiplier];
    return parameters.map (param => value ? `${param}: ${value};` : '').join ('');
  }
  return '';
};

export const m = createSpacingFn (['margin']) ('m');
export const mx = createSpacingFn (['margin-left', 'margin-right']) ('mx');
export const ml = createSpacingFn (['margin-left']) ('ml');
export const mr = createSpacingFn (['margin-right']) ('mr');
export const mt = createSpacingFn (['margin-top']) ('mt');
export const mb = createSpacingFn (['margin-bottom']) ('mb');
export const p = createSpacingFn (['padding']) ('p');
export const px = createSpacingFn (['padding-left', 'padding-right']) ('px');
export const pl = createSpacingFn (['padding-left']) ('pl');
export const pr = createSpacingFn (['padding-right']) ('pr');

export const spacing = css`
  ${m}
  ${mx}
  ${ml}
  ${mr}
  ${mt}
  ${mb}
  ${p}
  ${px}
  ${pl}
  ${pr}
`;
