import { either, propOr } from 'ramda';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Flex } from 'reflexbox';
import { filter } from 'sanctuary';

import { Modal } from '../../modal';
import { FormStyled, ButtonWrapper } from '../form.styled';
import { Button } from '../../button';
import { filterByGroup } from '../../../utils/auth';


const FormBuilder = ({
  fields = [],
  onSubmit,
  initialValues,
  inline = false,
  isEdit = false,
  loading = false,
  btnText = 'Create',
  externalForm,
  brandButton = false,
  saveBtnText = 'Save',
  userGroups,
  onSave,
  children,
  onChange,
  resetOnSubmit,
  watch,
  validationError = null,
  fieldNamePrefix = '',
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { handleSubmit, ...formProps } = useForm({ defaultValues: initialValues });
  const form = useMemo(() => externalForm || formProps, [externalForm, formProps]);

  const submit = values => {
    setIsSubmitted(true);
    if (saveClicked) {
      onSave(values)
    } else {
      onSubmit(values);
    }
    resetOnSubmit && form.reset();
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    if (initialValues && !isSubmitted && !externalForm) {
      form.reset(initialValues);
    }
  }, [initialValues, isSubmitted, form.reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const error = name => {
    const errorObj = propOr({}, name)(form.errors);

    return either(propOr('', 'message'), propOr('', 'type'))(errorObj);
  };

  const isVisible = field => {
    if (typeof field?.show === 'function') {
      return field.show(form.watch(field.watch));
    }

    return true;
  };

  const values = typeof onChange === 'function' ? form.watch(watch) : '';
  // useEffect (() => {
  //   if (typeof onChange === 'function') {
  //     onChange ({[fieldToWatch]:value});
  //   }
  // }, [onChange, watch, fieldToWatch]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(values);
    }
  }, [onChange, watch, values]);
  const content = useMemo(() => (
    <>
      <Flex flexWrap="wrap" width={'100%'}>
        {
          filter(field => filterByGroup(field, userGroups) && isVisible(field))(fields).map((field, idx) => (
            <Controller
              {...field}
              key={idx}
              name={`${fieldNamePrefix}${field.name}`}
              error={error(`${fieldNamePrefix}${field.name}`)}
              control={form.control}
              setValue={form.setValue}
            />
          ))
        }

        {children}
        <ButtonWrapper>
          {onSave ? <Button onClick={() => setSaveClicked(true)} brand={brandButton}>{loading ? 'Loading...' : saveBtnText}</Button> : <React.Fragment />}
          <Button onClick={() => setSaveClicked(false)} brand={brandButton}>{loading ? 'Loading...' : isEdit ? 'Edit' : btnText}</Button>
        </ButtonWrapper>
      </Flex>
      <Modal
        closable
        show={modalIsOpen}
        setShow={setIsOpen}
        variant={['small']}
        error={validationError}
        title="Confirm"
        description="Are you sure?"
      >
        <ButtonWrapper>
          <Button brand onClick={closeModal}>No</Button>
          <Button brand onClick={handleSubmit(submit)}>Yes</Button>
        </ButtonWrapper>
      </Modal>
    </>
  ), [fields, userGroups, form]); // eslint-disable-line react-hooks/exhaustive-deps
  return externalForm ? (
    <Fragment>{content}</Fragment>
  ) : (
    <FormStyled
      data-testid='form'
      inline={inline}
      onSubmit={
        isEdit ? async e => {
          e.preventDefault();
          const res = await form.trigger();
          setIsOpen(res);
        } : handleSubmit(submit)
      }
    >{content}</FormStyled>
  );
};
export default FormBuilder;
