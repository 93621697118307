import { is } from 'ramda';

import { ReactComponent as EmailIcon } from '../../../assets/images/email-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/CorrectField_Check.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search_Black.svg';
import { ReactComponent as DateIcon } from '../../../assets/icons/Date.svg';
import { ReactComponent as TimeIcon } from '../../../assets/icons/Time.svg';
import { ReactComponent as PasswordIcon } from '../../../assets/icons/Password_Hidden_Selected.svg';
import { FormInputIcon } from '../form.styled';


const builtInIcons = {
  email: FormInputIcon (EmailIcon),
  password: FormInputIcon (PasswordIcon),
  date: FormInputIcon (DateIcon),
  time: FormInputIcon (TimeIcon),
  search: FormInputIcon (SearchIcon),
  check: FormInputIcon (CheckIcon),
  close: FormInputIcon (CloseIcon),
};

export const getIcon = icon => {
  if (is (String, icon) && Boolean (builtInIcons[icon])) {
    return builtInIcons[icon];
  }

  return icon;
};

export const typeBasedIcon = type => {
  switch (type) {
    case 'email':
      return getIcon ('email');
    case 'password':
      return getIcon ('password');
    case 'date':
      return getIcon ('date');
    case 'time':
      return getIcon ('time');
    default:
      return '';
  }
};
