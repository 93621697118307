import styled from 'styled-components/macro';

import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';


export const Button = styled.button.attrs ({ children: <CloseIcon /> })`
  align-items: start;
  background-color: ${({ theme }) => theme.colors.transparent};
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  svg {
    stroke: ${({ theme }) => theme.colors.grey};
  }
`;

