import React from 'react';

import { ErrorStyled } from '../form.styled';

import { CheckboxStyled, CheckboxGroupStyled, CheckboxLabelStyled, CheckboxIcon } from './checkbox.styled';


const SingleCheckbox = ({
  name,
  label,
  error,
  tag = 'input',
  value,
  setValue,
  ...rest
}) => {
  const handleChange = ({ target: { value, checked } = {} }) => {
    rest.onChange (checked ? rest.id || true : false);
  };

  return (
    <CheckboxGroupStyled>
      <CheckboxStyled
        data-testid= {label}
        as={tag}
        {...rest}
        name={name}
        value={value}
        checked={value?.toString?.() ? value : rest.value}
        type="checkbox"
        onChange={handleChange}
      />
      <CheckboxLabelStyled htmlFor={name}>
        {label}
      </CheckboxLabelStyled>
      <CheckboxIcon />
      {error &&
        <ErrorStyled>{error}</ErrorStyled>}
    </CheckboxGroupStyled>
  );
};

export default SingleCheckbox;
