import styled from 'styled-components/macro';


export const NotAllowedStyled = styled.div`
align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
min-height: 90vh;
h1 {
  font-size: 2rem;
}
`;
