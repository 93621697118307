import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';


export const Column = styled.div`
  flex-direction: column;
  flex-basis: 48%;
  padding-right: 2%;
  ${props => props.center && css`justify-content: center`}
  ${props => props.marginTop && css`margin-top: ${props.marginTop}px`};
  ${props => props.marginBottom && css`margin-bottom: ${props.marginBottom}px`};
  ${props => props.width && css`width: ${props.width}px`};
`;


export const UploadItem = styled.div`
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 14px;
`;

export const CloseButton = styled.div.attrs ({ children: <CloseIcon /> })`
  cursor: pointer;
  svg {
    stroke: ${({ theme }) => theme.colors.grey};
    width: 15px;
  }
`;

export const CloseButtonWrapper = styled.div`
   position: absolute;
   top: 10px;
   right: 10px;
`;

