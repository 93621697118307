import React from 'react';
import { Flex } from 'reflexbox';
import styled, { css } from 'styled-components';

import { usePagination, DOTS } from './usePagination';
import { Button } from '../button';
import { ReactComponent as RightArrowIcon } from '../../assets/images/arrow-list.svg';


export const Pagination = ({
  totalPages = 10,
  currentPage = 0,
  pageSize = 0,
  siblingCount = 1,
  onSelectPage,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount: totalPages,
    siblingCount,
    pageSize
  });
  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
    >
      <PrevPageButton
        disabled={currentPage === 1}
        onClick={() => onSelectPage(currentPage - 1)}
      />

      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <PaginationButton
              active={currentPage === pageNumber}
              disabled={currentPage === pageNumber}
              onClick={() => onSelectPage(pageNumber)}
            >&#8230;</PaginationButton>
          );
        }

        return (
          <PaginationButton
            key={`pagination-${pageNumber}`}
            active={currentPage === pageNumber}
            disabled={currentPage === pageNumber}
            onClick={() => onSelectPage(pageNumber)}
          >{pageNumber}</PaginationButton>
        );
      })}

      <NextPageButton
        disabled={currentPage === lastPage}
        onClick={() => onSelectPage(currentPage + 1)}
      />
    </Flex>
  );
}

export default Pagination;

const PaginationButton = styled(Button)`
padding: 5px;
width: 40px;
height: 40px;
border-radius: 14px;
margin: 4px;
font-weight: 500;
color: ${({ theme }) => theme.colors.grey};

&:disabled {
  cursor: not-allowed;
}
background-color: ${({ theme }) => theme.colors.greyBg};
&:hover:not(:disabled) {
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greyBg};
}

${({ active, theme }) => active && css`
  color: ${theme.colors.dark};
  background-color: ${theme.colors.greyBg};
`}
`;

const NextPageButton = styled(PaginationButton).attrs({ children: <RightArrowIcon /> })`
&:hover:not(:disabled) {
  svg {
    height: 25px;
    stroke: ${({ theme }) => theme.colors.white};
  }
}
svg {
  height: 25px;
  stroke: ${({ theme }) => theme.colors.dark};
}
`;

const PrevPageButton = styled(NextPageButton)`
transform: rotateY(180deg);
`;

