import React from 'react';

import { ButtonStyled } from './button.styled';


const Button = ({
  children,
  icon,
  ...rest
}) => {
  const Icon = icon;
  return (
    <ButtonStyled {...rest} icon={Icon && true}>
      {icon && <Icon style={{ marginRight: 12 }} />}
      {children}
    </ButtonStyled>
  );
}

export default Button;
