export const extractFromLocation = (locationResponse = {}) => ({
  text: locationResponse.formatted_address?.split (',')[0],
  placeId: locationResponse.place_id,
  description: locationResponse.formatted_address,
  latLng: locationResponse.geometry?.location,
  bounds: locationResponse.geometry?.viewport,
  coordinates: {
    lat: locationResponse.geometry?.location.lat (),
    lng: locationResponse.geometry?.location.lng (),
  },
  country: extractFromAddress (locationResponse.address_components, 'country')?.long_name,
  countryCode: extractFromAddress (locationResponse.address_components, 'country')?.short_name,
  state: extractFromAddress (locationResponse.address_components, 'administrative_area_level_1')?.long_name,
  stateCode: extractFromAddress (locationResponse.address_components, 'administrative_area_level_1')?.short_name,
  city: extractFromAddress (locationResponse.address_components, 'locality')?.long_name,
  cityCode: extractFromAddress (locationResponse.address_components, 'locality')?.short_name,
  zipCode: extractFromAddress (locationResponse.address_components, 'postal_code')?.long_name,
  street: extractFromAddress (locationResponse.address_components, 'street_number')?.long_name,
});

export const extractFromAddress = (addressComponents = [], key) => addressComponents.find (address => address.types?.includes (key));

export const excludeLocationText = (predictions = [], allowedText = []) => {
  if (!allowedText || allowedText?.length === 0) return predictions;

  return predictions.filter (prediction => prediction.description.includes (allowedText));
};
