import React from 'react';
import styled, { css } from 'styled-components/macro';

import { Select } from '../forms';


const FilterWrapper = styled.div`
  min-width: 130px;
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 12px;
  ${({ theme }) => css`
  @media ${theme.device.wide} {
    margin-bottom: 0px;
  `}
`;

export function SelectList ({filterValue, setFilter, title, isMulti, options }) {
  const handleChange = selectedOption => {
    const newOption = isMulti ? selectedOption : selectedOption.value;
    setFilter (newOption !== 'All' ? newOption : undefined); // eslint-disable-line
  };

  return (
    <FilterWrapper>
      <div>{title}</div>
      <Select
        value={filterValue}
        options={options}
        onChange={handleChange}
        isMulti={isMulti}
        isFilter={true}
        size="small"
        noVerify
        returnFullOption
      />
    </FilterWrapper>
  );
}

export const multiSelectList = (rows, id, filterValue) => {
  const filteredRows = rows.filter (row => filterValue?.filter (fValue => fValue.label === row.values[id[0]])?.length > 0);
  return filterValue?.length > 0 ? filteredRows : rows;
};
