import React from 'react';

import { ListStyled } from './list.styled';


const List = ({ data, renderItem, onClick }) => (
  <ListStyled>
    {data.map ((item, idx) => <li onClick={() => onClick (item)} key={idx}>{renderItem (item)}</li>)}
  </ListStyled>
);

export default List;
