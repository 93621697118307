import React from 'react';

import { CardStyled } from './card.styled';


const Card = ({
  children,
  ...rest
}) => (
    <CardStyled {...rest}>{children}</CardStyled>
  );

export default Card;
