import React from 'react';

import { SignOutButton } from '../auth';

import { NotAllowedStyled } from './notAllowed.styled';


const NotAllowed = () => (
    <NotAllowedStyled>
      <h1>Sorry, You do not have permissions to access these pages right now.</h1>
      <SignOutButton />
    </NotAllowedStyled>
  );

export default NotAllowed;
