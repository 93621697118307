import styled from 'styled-components/macro';
import { color, layout, space, typography } from 'styled-system';


export const LinkStyled = styled.a`
  ${color}
  ${typography}
  ${space}
  ${layout}
`;
