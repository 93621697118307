/* stylelint-disable */
import styled from 'styled-components/macro';

import { FormGroup, inputHoverStyle, inputInteractiveStyle, inputRootStyle, inputDisabledStyle, inputFocusStyle } from '../form.styled';


export const SelectGroupStyled = styled (FormGroup)`
  width: 100%;
  .react-select {
    .react-select__control {
      ${inputRootStyle}
      ${inputInteractiveStyle}
      ${inputHoverStyle}
      ${inputDisabledStyle}

      &--is-focused {
        ${inputFocusStyle}
      }
      display: flex;
      .react-select__value-container {
        min-height: 15px;
        padding: 0;
        .react-select__single-value + div {
          margin: 0;
          padding: 0;
        }
        .react-select__input {

        }
      }
      .react-select__indicators {
        height: 23px;
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__indicator {
          svg {

          }
        }
      }
      .react-select__placeholder {

      }
      .react-select__placeholder + div {
        margin: 0;
        padding: 0;
      }
    }
    .react-select__menu {
      background-color: ${({ theme }) => theme.components.input.backgroundColorFocus};
      border: ${({ theme }) => `${theme.components.input.borderWidth}px solid ${theme.components.input.select.menuBorderColor}`};
      border-radius: ${({ theme }) => theme.components.input.borderRadius};
      color: ${({ theme }) => theme.components.input.fontColor};
      .react-select__menu-list {
        .react-select__menu-notice {
          font-weight: ${({ theme }) => theme.components.input.fontWeight};
        }
        .react-select__option {
          font-size: ${({ theme }) => theme.components.input.select.menuOptionFontSize};
          &--is-focused {
            background-color: ${({ theme }) => theme.components.input.select.menuOptionFocusBgColor};
            color: ${({ theme }) => theme.components.input.select.menuOptionFocusColor};
          }
          &--is-selected {
            background-color: ${({ theme }) => theme.components.input.select.menuOptionActiveBgColor};
            color: ${({ theme }) => theme.components.input.select.menuOptionActiveColor};
          }
        }
      }
    }
    ${({ theme, size }) => Boolean (size) && `
      .react-select__menu {
        .react-select__menu-list {
          .react-select__menu-notice,
          .react-select__option {
            font-size: ${theme.components.input[size].fontSize};
            padding: ${theme.components.input[size].paddingY}px ${theme.components.input[size].paddingX}px;
          }
        }
      }
    `}
    &:focus,
    .react-select__value-container:focus,
    .react-select__control--is-focused,
    .react-select__menu {
      outline: none;
      box-shadow: none;
    }
  }
`;

export const reactSelectStyles = {
  // control: (base, state) => ({
  //   ...base,
  //   'borderColor': state.selectProps?.invalid ? theme.colors.error : rgba (theme.colors.darkBrand, 0.25),
  //   'fontWeight': '400',
  //   '&:hover': {
  //     borderColor: theme.colors.brand,
  //   },
  // }),
  // option: base => ({
  //   ...base,
  //   'fontWeight': '400',
  // }),
};
