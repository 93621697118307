import * as R from 'ramda';
import Validate from 'validate.js';

export function isEmail (email) {
  // eslint-disable-next-line
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return emailRegex.test (email);
}

export function isPhone (phone) {
  const phoneRegex = /^[0-9]{10,15}$|^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/;
  return phoneRegex.test (phone);
}

export function validateEmail (email) {
  if(!email) return true
  return !isEmail (email) ? 'Email is not valid' : true;
}

export function validatePhone (phone) {
  if(!phone) return true
  return !isPhone (phone) ? 'Phone is not valid' : true;
}

export function validateOnChange (constraints, type, content, setState, setErrors, extra) {
  setState (content);
  const toValidate = {};
  toValidate[type] = content;
  if (extra) {
    toValidate[extra.type] = extra.content;
  }
  const result = Validate (toValidate, constraints);
  if (R.isNil (result)) {
    setErrors (result);
  } else {
    setErrors (result[type]);
  }
}

export function validateForm (content, constraints) {
  const validateResult = Validate (content, constraints);
  return validateResult;
}
