import React from 'react';
import { Box, Flex } from 'reflexbox';

import { debounce } from '../../../utils';
import { Alert } from '../../alert';
import { Input } from '../Input';

import { FormFieldStyled } from './formField.styled';


export const FormField = ({
  name,
  label,
  error,
  onChange,
  ...rest
}) => {
  const deb = debounce (onChange, 1000);

  return (
    <FormFieldStyled>
      <Flex flexDirection="column">
        {error &&
          <Box width={1} mb={3}>
            <Alert>{error}</Alert>
          </Box>}
        <Box width={1} mx="auto">
          <Input
            name={name}
            label={label}
            error={Boolean (error)}
            tag="textarea"
            rows={5}
            onChange={({ target: { value } = {} }) => deb (value)}
            {...rest}
          />
        </Box>
      </Flex>
    </FormFieldStyled>
  );
};
