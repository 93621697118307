const colors = {
    brand: '#5CE1E6',
    dark: '#243239',
    white: '#FFF',
    black: '#000',
    darkBrand: '#1B4F4D',
    greyBrand: '#E1E4EA',
    secondary: '#3EC5E1',
    tertiary: '#29B6D4',
    secondaryGrey: '#97A6B7',
    lightBrand: 'rgba(41, 182, 212, 0.5)',
    darkBlue: '#0541B9',
    lightBlue: '#8097B1',
    lighterBlue: '#77A1E9',
    drawerBg: 'rgba(32,39,43,0.95)',
    drawerBorder: 'rgba(242,242,242,0.1)',
    shadowColor: '#DFE4F0',
    error: '#F60039',
    lightBg: '#fff',
    darkShadow: 'rgba(0,0,0, 0.5)',
    lightText: '#fff',
    darkText: '#1B4F4D',
    Bluewood: '#364B62',
    warning: '#ff8900',
    transparent: 'rgba(0,0,0,0)',
    grey: '#B0B0B0',
    greyBg: 'rgba(221,226,232,0.95)',
    lightGrey: '#9D9D9D',
    lighterGrey: '#F7F7F7',
    lightestGrey: '#F2F4F9',
    darkGrey: '#484848',
    lightGreen: '#DDF6EA',
    green: '#07DF77',
    red: '#FF5F58',
    orange: '#F85C1A',
    lightRed: '#F6DDE3',
    tomatoRed:"#FF0000",
    blueGradient: ['#1573D0', '#1B5AD9'],
    redGradient: ['#F60039', '#C71A42'],
    greenGradient: ['#07DF77', '#17B669'],
    whiteGradient: ['#FFFFFF', '#FFFFFF'],
    orangeGradint: ['#FF7E46', '#F85C1A'],
};

export default colors;
