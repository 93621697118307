import { Auth } from 'aws-amplify';
import React from 'react';

import { SignOutButtonStyled } from './signOutButton.styled';


const SignOutButton = () => {
  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (<SignOutButtonStyled  onClick={signOut}>Sign Out</SignOutButtonStyled>)
}


export default SignOutButton;
