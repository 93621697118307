import { gql } from '@apollo/client';


export const getUserQuery = gql`
query getAuthenticatedUserDetails {
  getAuthenticatedUserDetails {
    id
    firstName
    lastName
    email
    phone
    type
    providerType
    avatarUrl
    tenant {
      name
      tenantType
    }
  }
}
`;


