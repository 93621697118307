import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import history from '../../history';

import { LinkStyled } from './styles';


function isLeftClickEvent (event) {
  return event.button === 0;
}

function isModifiedEvent (event) {
  return Boolean (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function handleClick (props, event) {
  if (props.onClick) {
    props.onClick (event);
  }

  if (isModifiedEvent (event) || !isLeftClickEvent (event)) {
    return;
  }

  if (event.defaultPrevented === true) {
    return;
  }

  event.preventDefault ();
  history.push (props.to); // eslint-disable-line no-restricted-properties
}

export default function Link (props) {
  const { to, children, ...attrs } = props;

  return (
    <LinkStyled href={to} {...attrs} onClick={e => handleClick (props, e)}>
      {children}
    </LinkStyled>
  );
}

export function Redirect ({ to }) {

  useEffect (() => {
    // eslint-disable-next-line no-restricted-properties
    history.push (to);
  }, [to]);

  return <React.Fragment />;
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  onClick: null,
};
