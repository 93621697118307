const family = '\'ProximaNova\', \'sans-serif\'';

const size = {
  h1: '38px',
  h2: '34px',
  h3: '30px',
  h4: '24px',
  h5: '20px',
  h6: '19px',
  input: '18px',
  regular: '14px',
  medium: '13px',
  small: '12px',
  tiny: '8.5px',
  large: '17px',
};

const style = {
  h1: {
    fontFamily: family,
    fontWeight: 'bold',
    fontSize: size.h1,
  },
  h2: {
    fontFamily: family,
    fontWeight: 'bold',
    fontSize: size.h2,
  },
  h3: {
    fontFamily: family,
    fontSize: size.h3,
    fontWeight: 500,
  },
  h4: {
    fontFamily: family,
    fontSize: size.h4,
    fontWeight: 400,
  },
  h5: {
    fontFamily: family,
    fontSize: size.h5,
  },
  h6: {
    fontFamily: family,
    fontSize: size.h6,
  },
  normal: {
    fontFamily: family,
    fontSize: size.regular,
    fontWeight: 400,
  },
  large: {
    fontSize: size.large,
  },
  description: {
    fontFamily: family,
    fontSize: size.medium,
  },
};

const Fonts = {
  size,
  style,
  family,
};

export default Fonts;
