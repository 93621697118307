import styled from 'styled-components/macro';
import { theme } from '../../../styles';
import { rgba } from 'polished';

export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: rgba(theme.colors.black, 0.1), // '#eeeeee'
  borderStyle: 'dashed',
  backgroundColor: theme.colors.white, // '#fafafa'
  color: theme.colors.grey, // '#bdbdbd'
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: '10px',
};

export const activeStyle = {
  borderColor: rgba(theme.colors.black, 0.6) // '#adadad'
};

export const acceptStyle = {
  borderColor: rgba(theme.colors.black, 0.6) // '#adadad'
};

export const rejectStyle = {
  borderColor: theme.colors.red // '#ff1744'
};

export const Wrapper = styled.div`
  width: 100%;
`;

