import styled, { css } from 'styled-components/macro';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { Card } from '../../card';

export const BulkStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
      ${({ theme }) => {
         return css`
          color: ${theme.components.primaryButton.activeColor};
        `;
      }}
      text-decoration: none;
      margin-top: 25px;
  }
`;

export const PreviewContainer = styled(Card)`
  flex-direction: row;
  margin: 10px;
  display: flex;
  width: 100%;
  padding: 0;
`;

export const PreviewInner = styled.div`
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  display: flex;
`;


export const CloseButton = styled.div.attrs ({ children: <CloseIcon /> })`
  cursor: pointer;
  svg {
    stroke: ${({ theme }) => theme.colors.grey};
    width: 15px;
  }
`;
