import React from 'react';
import { path } from 'ramda';
import { Box } from 'reflexbox/styled-components';

import { ReactComponent as Approved } from '../../assets/images/status-approved.svg';
import { ReactComponent as Pending } from '../../assets/images/status-pending.svg';
import { ReactComponent as Rejected } from '../../assets/images/status-rejected.svg';
import { ReactComponent as NotViewed } from '../../assets/images/status-not-viewed.svg';
import { StatusText } from './status.styled';


const CASE_STATUS = {
  inProgress: {
    icon: Pending,
    text: 'Pending',
    color: '#f3ca3e',
  },
  billerConfirmed: {
    icon: Approved,
    text: 'Confirmed By Biller',
    color: '#2ac940',
  },
  approved: {
    icon: Approved,
    text: 'Approved',
    color: '#2ac940',
  },
  rejected: {
    icon: Rejected,
    text: 'Rejected',
    color: '#ff5e58',
  },
  draft: {
    icon: NotViewed,
    text: 'Draft',
    color: '#3399ff',
  },
};

const Status = ({ status }) => {
  const Icon = status ? path ([status, 'icon'], CASE_STATUS) : Pending;
  const text = status ? path ([status, 'text'], CASE_STATUS) : 'Pending';
  const color = status ? path ([status, 'color'], CASE_STATUS) : '#97A6B7';
  return (
    <Box mr={'10px'}>
      <StatusText color={color}>{text}</StatusText>
      <Icon />
    </Box>
  );
};

export default Status;
