import React from 'react';

import history from '../../history';
import { hasAnyGroup, USER_GROUPS } from '../../utils/auth';
import Container from '../layouts/app-layout/Container';
import { ReactComponent as CloseMenuIcon } from '../../assets/images/close-menu-icon.svg';

import { navItems } from './config';
import { SidebarLink, SidebarStyled, IconWrapper, Logo, LogoLink, MenuIcon } from './sidebar.styled';


const Sidebar = ({ userGroups, active, setShowMenu }) => {
  const isSuperadmin = hasAnyGroup (userGroups, [USER_GROUPS.superAdmin]);
  const isBiller = hasAnyGroup (userGroups, [USER_GROUPS.biller]);
  const isProvider = hasAnyGroup (userGroups, [USER_GROUPS.provider]);
  const isOfficeAdmin = hasAnyGroup (userGroups, [USER_GROUPS.officeAdmin]);
  const group = isSuperadmin ? 'superadmin' : isBiller || isOfficeAdmin ? 'biller' : isProvider ? 'provider' : 'globaladmin';
  const items = navItems[group];

  return (
    <SidebarStyled active={active}>
      <MenuIcon onClick={() => setShowMenu (false)}>
        <CloseMenuIcon />
      </MenuIcon>
      <LogoLink to="/" aria-label="home page"><Logo src="/app-icon.png" alt="clomr logo" />Clomr</LogoLink>
      <Container display="flex" justifyContent="space-between">
        <ul>
          {
            items?.map (item => {
              const Icon = item.icon;
              return (
                <li key={item.url}>
                  <SidebarLink to={item.url} onClick={() => setShowMenu (false)} active={`${history.location.pathname === item.url}`}>
                    {item.icon && <IconWrapper><Icon /></IconWrapper>}
                    {item.text}
                  </SidebarLink>
                </li>
              );
            })
          }
        </ul>
      </Container>
    </SidebarStyled>
  );
};

export default Sidebar;
