import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';

import { LabelStyled  } from '../form.styled';

import { AutoCompleteLocationWrapper, reactSelectAutoCompleteStyle } from './AutoCompleteLocation.style';
import { excludeLocationText, extractFromLocation } from './helpers';


const defaultOptions = {
  componentRestrictions: { country: 'us' },
};

const AutoCompleteLocation = ({
  name,
  placeholder,
  initialOption,
  initialSearchTerm,
  autoCompletePlacesOptions = {},
  formatter = extractFromLocation,
  placeId,
  allowedText,
  disabled,
  label,
  value,
  wrapperComponent: WrapperComponent = AutoCompleteLocationWrapper,
  style = reactSelectAutoCompleteStyle,
  ...rest
}) => {
  const mapRef = useRef ();
  const [selectedOption, setSelectedOption] = useState ();
  const [autoCompleteService, setAutoCompleteService] = useState ();
  const [placeDetailsService, setPlaceDetailsService] = useState ();
  const [suggestions, setSuggestions] = useState ([]);

  // Set google maps places (autoComplete) services
  useEffect (() => {
    if (process.env.REACT_APP_BROWSER) {
      // eslint-disable-next-line
      setAutoCompleteService (new google.maps.places.AutocompleteService ());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.REACT_APP_BROWSER]);

  // Set google maps places (places) services
  useEffect (() => {
    if (process.env.REACT_APP_BROWSER && mapRef.current) {
      const map = document.getElementById ('direction')?.querySelector ('iframe');
      // eslint-disable-next-line
      setPlaceDetailsService (new google.maps.places.PlacesService (map || mapRef.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.REACT_APP_BROWSER, mapRef.current]);

  // Set initital option
  useEffect (() => {
    if (value && suggestions.length === 0) {
      setSuggestions ([{ label: value }]);
      setSelectedOption ({ label: value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Fetch place details if placeId provided
  useEffect (() => {
    if (placeId && placeDetailsService && suggestions.length === 0) {
      fetchPlaceDetails ({ value: placeId }, place => {
        const option = {
          label: place.description,
          value: placeId,
        };
        setSuggestions ([option]);
        setSelectedOption (option);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId, placeDetailsService]);

  // Search for initial search term and set the closest suggestion
  useEffect (() => {
    if (initialSearchTerm && autoCompleteService && suggestions.length === 0) {
      fetchSuggestions (initialSearchTerm, predictions => {
        setSelectedOption (predictions[0]);
        fetchPlaceDetails (predictions[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSearchTerm, autoCompleteService, allowedText]);

  /**
   * Fetchers
   */
  const fetchSuggestions = (value, cb) => {
    if (value && typeof value === 'string') {
      autoCompleteService?.getPredictions ({
        ...defaultOptions,
        ...autoCompletePlacesOptions,
        input: value,
      }, (predictions, status) => {
        handleSuggestions (predictions, status, cb);
      });
    }
  };

  const fetchPlaceDetails = (option = {}, cb) => {
    if (option && option.value && typeof option.value === 'string') {
      placeDetailsService?.getDetails ({
        placeId: option.value,
        fields: ['address_components', 'formatted_address', 'geometry', 'name', 'place_id'],
      }, (place, status) => {
        handlePlaceDetails (place, status, cb);
      });
    }
  };

  /**
   * Handlers
   */
  const handleSuggestions = (predictions, status, cb) => {
    // eslint-disable-next-line
    if (process.env.REACT_APP_BROWSER && status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
      return;
    }

    const allowedPredictions = excludeLocationText (predictions, allowedText);

    const opts = allowedPredictions.map (prediction => ({
      label: prediction.description,
      value: prediction.place_id,
    }));

    setSuggestions (opts);
    if (cb && typeof cb === 'function') cb (opts);
  };

  const handlePlaceDetails = (place, status, cb) => {
    // eslint-disable-next-line
    if (process.env.REACT_APP_BROWSER && status !== google.maps.places.PlacesServiceStatus.OK || !place) {
      return;
    }
    rest.setValue ('address.text', formatter (place).text);
    rest.setValue ('address.city', formatter (place).city);
    rest.setValue ('address.state', formatter (place).state);
    rest.setValue ('address.postalCode', formatter (place).zipCode);
    if (cb && typeof cb === 'function') cb (formatter (place));
  };

  const handleSelect = option => {
    setSelectedOption (option);
    fetchPlaceDetails (option);
  };

  return (
    <WrapperComponent>
      {label &&
        <LabelStyled htmlFor={name}>{label}</LabelStyled>}
      <Select
        name={name}
        inputId={name}
        options={suggestions}
        placeholder={placeholder}
        value={selectedOption}
        className="react-select"
        classNamePrefix="react-select"
        onInputChange={fetchSuggestions}
        onChange={handleSelect}
        isDisabled={disabled}
        isClearable
        styles={style}
        noOptionsMessage={({ inputValue }) => inputValue ? `No Suggestions for "${inputValue}"` : 'Search for location'}
      />
      <div ref={mapRef} style={{ display: 'none' }} />
    </WrapperComponent>
  );
};

export default AutoCompleteLocation;
