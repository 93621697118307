import React, { useEffect, useMemo, useState } from 'react';
import { is, propEq, find, map } from 'ramda';
import { useQuery } from '@apollo/client';
import ReactSelect from 'react-select';

import { ErrorStyled, LabelStyled } from '../form.styled';
import { listCasesQuery } from '../../../routes/cases/graphql';
import { useDebounce } from '../../../utils';

import { reactSelectStyles, SelectGroupStyled } from './select.styled';


const Select = ({
  name,
  label,
  error,
  options: opts,
  query,
  tag = 'select',
  returnFullOption = false,
  formatter = v => v,
  isFilter = false,
  limit = 1000,
  size,
  queryVariables,
  ...rest
}) => {
  // Check if 'options' is a Function or an Array
  // to set options state initital value
  const _options = is (Array, opts) ? opts : [];
  const [options, setOptions] = useState (_options);
  const [searchTerm, setSearchTerm] = useState ('');
  const debouncedValue = useDebounce (searchTerm, 500);
  // if 'query' is defiend use it to fetch the data from graphql
  const { data } = useQuery (query || listCasesQuery, {
    skip: !query,
  });
  // if 'options' is a function invoke it, and set options
  useEffect (() => {
    if (is (Function, opts)) {
      const optsResult = opts (debouncedValue);
      is (Promise, optsResult) ? optsResult.then (setOptions) : setOptions (optsResult);
    }
  }, [opts, debouncedValue]);

  useEffect (() => {
    if (!is (Function, opts)) {
      setOptions (opts);
    }
  }, [opts]);

  // Format the final options with the proper formatter
  const formattedOptions = useMemo (() => {
    const finalOptions = data?.options || options || [];

    // Format and slice options
    return finalOptions.map (formatter).slice (0, limit);
  }, [options, data?.options, formatter, limit]);

  const settings = useMemo (() => ({
    onInputChange: is (Function, opts) ? setSearchTerm : undefined, // eslint-disable-line
  }), [opts]);

  const handleChange = selectedOption => {
    if (returnFullOption) {
      rest.onChange (selectedOption);
    } else if (rest.isMulti) {
      rest.onChange (selectedOption?.map (option => is (Object, option) ? option.value : option));
    } else {
      rest.onChange (is (Object, selectedOption) ? selectedOption.value : selectedOption);
    }
  };
  return (
    <SelectGroupStyled data-testid={`${name}-select`} size={size} aria-invalid={Boolean(error)} invalid={Boolean(error)}>
      {label &&
        <LabelStyled htmlFor={name}>{label}</LabelStyled>}
      <ReactSelect
        as={tag}
        name={name}
        inputId={name}
        styles={reactSelectStyles}
        className="react-select"
        classNamePrefix="react-select"
        invalid={Boolean (error)}
        {...rest}
        options={formattedOptions}
        value={isFilter ? rest.value : rest.isMulti ? map((value)=> find(propEq('value', value))(formattedOptions))(rest.value || []) : formattedOptions?.find (option => (is (Object, option) ? option.value : option) === rest.value)}
        onChange={handleChange}
        {...settings}
      />
      {error &&
        <ErrorStyled>{error}</ErrorStyled>}
    </SelectGroupStyled>
  );
};

export default Select;
