import React, { useState } from 'react';
import moment from 'moment';
import { Box, Flex } from 'reflexbox';

import { Button } from '../../button';


const options = [
  {
    label: '90 Days',
    value: {
      fromDate: moment ().add (-3, 'M'),
      toDate: moment (),
    },
  },
  {
    label: 'Last Month',
    value: {
      fromDate: moment ().add (-1, 'M'),
      toDate: moment (),
    },
  },
  {
    label: 'Last year',
    value: {
      fromDate: moment ().add (-12, 'M'),
      toDate: moment (),
    },
  },
];

export function DateRangeFilter ({
  column: { setFilter },
}) {
  const [dateRange, setDateRange] = useState (options[0].value);
  return (
    <>
      <Flex flexDirection="row" mb={1} width={'440px'} ml={'-10px'}>
        {options.map ((item, i) => (
          <Box width={1 / 3} m={1} key={i}>
            <Button
              width={'130px'}
              active={dateRange === item.value}
              tag="button"
              onClick={() => {
                setFilter (item.value);
                setDateRange (item.value);
              }}
              bordered
              mr={1}>
              {item.label}
            </Button>
          </Box>
        ))}
      </Flex>
    </>
  );
}

export const filterDateRange = (rows, id, filterValue) => (filterValue?.['toDate'] || filterValue?.['fromDate']) ? rows.filter (elem => {
  const fromDate = filterValue?.['fromDate'] ? moment (elem.values.date).isAfter (filterValue['fromDate']) : true;
  const toDate = filterValue?.['toDate'] ? moment (elem.values.date).isBefore (filterValue['toDate']) : true;
  return fromDate && toDate;
}) : rows;
