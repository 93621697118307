import React from 'react';
import { createBrowserHistory } from 'history';


const history = createBrowserHistory ();

export const Link = ({ to, onClick, children, tag: Tag = 'a', ...rest }) => {

  const onRedirect = event => {
    event.preventDefault ();
    // eslint-disable-next-line no-restricted-properties
    history.push (to);
    onClick && onClick ();
  };

  return (
    <Tag {...rest} href={to} onClick={onRedirect}>{children}</Tag>
  );
};

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history
export default history;
