import styled from 'styled-components/macro';


export const AutoCompleteLocationWrapper = styled.div`
  padding-right: 2%;
  width: 48%;
  .react-select {
    width: 100%;
  }
`;

export const reactSelectAutoCompleteStyle = {
  control: base => ({
    ...base,
    height: '54px', // eslint-disable-line quote-props
    backgroundColor: '#F2F4F9', // eslint-disable-line quote-props
    border: '2px solid #F2F4F9', // eslint-disable-line quote-props
    borderRadius: '8px', // eslint-disable-line quote-props
    color: '#243239', // eslint-disable-line quote-props
    boxShadow: 'none', // eslint-disable-line quote-props
    '&:hover': {
      borderColor: '#5CE1E6', // eslint-disable-line quote-props
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#243239',
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: 'transparent',
  }),
  menu: base => ({
    ...base,
    zIndex: 1,
  }),
};
