import React, { useEffect, useState } from 'react';
import { not, isEmpty } from 'ramda';
import { Dropzone } from '../Dropzone';
import { Button } from '../../button';
import { ReactComponent as FileIcon } from '../../../assets/images/csv-file-icon.svg';

import { BulkStyled, PreviewContainer, PreviewInner, CloseButton } from './bulkUpload.styled';
import { handleAddCSV, uploadCSV } from './helper';
import { Modal } from '../../modal';


const BulkUpload = ({
  icon,
  name,
  label,
  accept = '.csv',
  mutation,
  mutationFormatter = (result) => result,
  mutationCallback = () => { },
  csvFile,
  validateCSV,
  errorCallback,
  errorMessage,
  successMessage,
  loading,
  ...rest
}) => {
  const [file, setFile] = useState();
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');


  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [errorMessage]); // eslint-disable-line

  useEffect(() => {
    if (successMessage) {
      setSuccess(successMessage);
    }
  }, [successMessage]);

  const uploadFile = () => {
    if (not(isEmpty(file))) {
      uploadCSV({ item: file, mutation, mutationFormatter, mutationCallback });
    }
  }
  const onClick = () => {
    setShow(true);
  }

  const onClose = () => {
    setShow(false);
    setValid(false);
    setError('');
    setSuccess('');
  }

  const onClear = () => {
    setValid(false);
    setError('');
    setSuccess('');
    setFile ('');
  }


  const getFile = (file) => {
    handleAddCSV(file, setFile, validateCSV, setError, setValid)
  }

  return (
    <React.Fragment>
      <Button bordered active tag="button" icon={icon} onClick={onClick} {...rest}>
        {label}
      </Button>
      <Modal show={show} setShow={(status) => setShow(status)} onClose={onClose} closable={true} variant={'wide'}>
        <BulkStyled>
          <Dropzone
            errorMessage={error}
            successMessage={success}
            getFile={getFile}
            uploadFile={uploadFile}
            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            message="Drag and drop file here, or click to select file"
          />
          {valid ?
            <React.Fragment>
              <PreviewContainer>
                <PreviewInner>
                  <div><FileIcon /><text>{file?.file?.name}</text></div>
                  <CloseButton onClick={onClear} />
                </PreviewInner>
              </PreviewContainer>
              <Button disabled={Boolean(error || success)} onClick={uploadFile} brand={true}>{loading ? 'Uploading...' : 'Upload File'}</Button>
            </React.Fragment>
            : <React.Fragment />
          }
          <a href={csvFile} download={true}>Download CSV File</a>
        </BulkStyled>
      </Modal>
    </React.Fragment>
  );
};

export default BulkUpload;
