import React from 'react';
import { is, equals } from 'ramda';

import { ErrorStyled, LabelStyled } from '../form.styled';

import { RadioStyled, RadioWrapperStyled, RadioGroupStyled, RadioLabelWrapper, RadioLabelStyled } from './radio.styled';


const Radio = ({
  name,
  label: _label,
  error,
  tag = 'input',
  options = [],
  ...rest
}) => (
    <RadioWrapperStyled hidden={Boolean (rest?.hidden)} invalid={Boolean (error)}>
      <RadioLabelWrapper><RadioLabelStyled invalid={Boolean (error)}>{_label}</RadioLabelStyled></RadioLabelWrapper>
      {options?.map?.((option, idx) => {
        const value = is (Object, option) ? option.value : option;
        const label = is (Object, option) ? option.label : option;

        return (
          <RadioGroupStyled hidden={Boolean (rest?.hidden)} key={idx}>
            <RadioStyled
              data-testid={label}
              as={tag}
              {...rest}
              name={name}
              value={value}
              checked={equals (rest.value, value.toString?.() ?? value)}
              type="radio"
            />
            <LabelStyled htmlFor={name}>
              {label}
            </LabelStyled>
          </RadioGroupStyled>
        );
      })}
      {error &&
        <ErrorStyled>{error}</ErrorStyled>}
    </RadioWrapperStyled>
  );

export default Radio;
