import { Colors, Fonts, Metrics } from '../assets/themes';

const breakpoints = ['540px', '720px', '960px', '1140px', '1400px']
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]
breakpoints.xxl = breakpoints[4] 


const sizes = {
  mobile: '540px',
  tablet: '720px',
  desktop: '960px',
  large: '1140px',
  xLarge: '1400px',
};

const theme = {
  breakpoints,
  colors: Colors,
  fonts: Fonts,
  metrics: Metrics,
  space: {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '1rem',
    4: '1.5rem',
    5: '3rem',
  },
  device: {
    mobile: `(max-width: ${sizes.mobile})`,
    tablet: `(max-width: ${sizes.tablet})`,
    desktop: `(max-width: ${sizes.desktop})`,
    large: `(max-width: ${sizes.large})`,
    standard: `(min-width: ${sizes.desktop}) and (max-width: ${sizes.large})`,
    wide: `(min-width: ${sizes.xLarge})`,
  },
  components: {
    form: {
      backgroundColor: Colors.transparent,
      boxShadow: 'none',
      borderColor: Colors.darkBrand,
      borderRadius: Metrics.inputRadius,
      borderWidth: Metrics.borderWidth,
      paddingX: 25,
      paddingY: 25,
      group: {
        marginBottom: '1rem',
      },
    },
    table: {
      backgroundColor: Colors.white,
      boxShadow: '0 0 15px 0 rgba(229,233,234,0.45)',
      borderRadius: Metrics.tableRadius,
      borderWidth: Metrics.borderWidthThin,
      padding: '20px',
      td: {
        borderBottom: `1px dotted ${Colors.lightBlue}`,
      },
    },
    greyCard: {
      backgroundColor: Colors.greyBg,
      borderRadius: '20px',
      boxShadow: '0px 5px 28px -9px rgba(0,0,0,0.1)',
      padding: '2rem 2rem',
      marginBottom: '5px',
    },
    whiteCard: {
      backgroundColor: Colors.lightBg,
      borderRadius: '20px',
      boxShadow: '0px 5px 28px -9px rgba(0,0,0,0.1)',
      padding: '2rem 2rem',
      marginBottom: '5px',
    },
    metricsBanner: {
      backgroundColor: Colors.white,
      boxShadow: '0 6px 23px 0 rgba(229,233,234,0.45)',
      borderRadius: '30px',
      padding: '10px',
      label: {
        color: Colors.grey,
        fontSize: '14px',
      },
      value: {
        color: Colors.darkText,
        fontSize: '24px',
        fontWeight: 700,
      },
    },
    input: {
      backgroundColor: Colors.lightestGrey,
      backgroundColorFocus: Colors.white,
      backgroundColorDisabled: Colors.lightestGrey,
      boxShadow: 'none',
      boxShadowFocus: 'none',
      boxShadowDisabled: 'none',
      borderColor: Colors.lightestGrey,
      borderColorFocus: Colors.brand,
      borderColorDisabled: Colors.grey,
      iconColor: Colors.greyBrand,
      iconColorFocus: Colors.brand,
      iconColorDisabled: Colors.grey,
      fontColor: Colors.dark,
      fontColorFocus: Colors.dark,
      fontColorDisabled: Colors.grey,
      borderRadius: Metrics.inputRadius,
      borderWidth: Metrics.borderWidth,
      successColor: Colors.green,
      errorColor: Colors.error,
      iconSuccess: '',
      iconError: '',
      iconWidth: 21.3333,
      paddingX: 15,
      paddingY: 15,
      fontSize: Fonts.style.normal.fontSize,
      fontWeight: '500',
      fontFamily: Fonts.style.normal.fontFamily,
      transition: '.2s ease background-color, .2s ease border-color',
      small: {
        fontSize: Fonts.size.medium,
        paddingX: 7.5,
        paddingY: 7.5,
        iconWidth: 21.3333,
        borderWidth: Metrics.borderWidth,
      },
      select: {
        menuOptionFontSize: Fonts.style.normal.fontSize,
        menuBorderColor: Colors.greyBrand,
        menuOptionFocusColor: Colors.dark,
        menuOptionFocusBgColor: Colors.greyBrand,
        menuOptionActiveColor: Colors.white,
        menuOptionActiveBgColor: Colors.brand,
      },
      label: {
        fontColor: Colors.darkBrand,
        fontSize: Fonts.style.normal.fontSize,
        fontWeight: '500',
        fontFamily: Fonts.style.normal.fontFamily,
        textTransform: 'capitalize',
        margin: 0,
      },
      errorMessage: {
        fontColor: Colors.error,
        fontSize: Fonts.style.normal.fontSize,
        fontWeight: '500',
        fontFamily: Fonts.style.normal.fontFamily,
        textTransform: 'capitalize',
        margin: '.25rem 0 0',
      },
    },
    checkbox: {
      icon: '',
      height: 20,
      backgroundColor: Colors.white,
      backgroundColorFocus: Colors.white,
      backgroundColorDisabled: Colors.lightestGrey,
      boxShadow: 'none',
      boxShadowFocus: 'none',
      boxShadowDisabled: 'none',
      borderColor: Colors.brand,
      borderColorFocus: Colors.brand,
      borderColorDisabled: Colors.grey,
      iconColor: Colors.greyBrand,
      iconColorFocus: Colors.brand,
      iconColorDisabled: Colors.grey,
      borderRadius: Metrics.checkboxRadius,
      borderWidth: Metrics.borderWidth,
      label: {
        fontColor: Colors.dark,
        fontSize: Fonts.style.normal.fontSize,
        fontWeight: '500',
        fontFamily: Fonts.style.normal.fontFamily,
        padding: '0 0 0 2rem',
        lineHeight: '1.45',
      },
    },
    radio: {
      height: 20,
      innerCircleHeight: 12,
      backgroundColor: Colors.white,
      backgroundColorFocus: Colors.brand,
      backgroundColorDisabled: Colors.lightestGrey,
      boxShadow: 'none',
      boxShadowFocus: 'none',
      boxShadowDisabled: 'none',
      borderColor: Colors.greyBrand,
      borderColorFocus: Colors.brand,
      borderColorDisabled: Colors.grey,
      iconColor: Colors.greyBrand,
      iconColorFocus: Colors.brand,
      iconColorDisabled: Colors.grey,
      borderRadius: '100px',
      borderWidth: Metrics.borderWidth,
      label: {
        fontColor: Colors.dark,
        fontSize: Fonts.style.normal.fontSize,
        fontWeight: '500',
        fontFamily: Fonts.style.normal.fontFamily,
        padding: '0 0 0 2rem',
        lineHeight: '1.45',
      },
      group: {
        margin: '0 0 .5rem 0',
      },
    },
    primaryButton: {
      backgroundColor: Colors.white,
      activeBackgroundColor: Colors.white,
      color: Colors.lightGrey,
      borderRadius: '30px',
      borderColor:  Colors.grey,
      activeColor: Colors.black,
      activeBulkColor:  Colors.Bluewood,
      activeBorderColor:  Colors.black,
      activeBulkBorderColor:  Colors.Bluewood,
    },
    modal: {
      backgroundColor: Colors.white,
      borderRadius: '12px',
      boxShadow: '0 6px 23px 0 rgba(229,233,234,0.45)',
      height: 'fit-content',
      width: '600px',
      zIndex: 99999,
      headerBackgroundColor: Colors.white,
      headerColor: Colors.darkBrand,
      headerPadding: '1rem',
      descriptionColor: Colors.darkBrand,
      descriptionPadding: '2rem 0',
      bodyPadding: '2rem',
      backdropBackgroundColor: 'rgba(0,0,0,.4)',
      variants: {
        small: {
          height: 'fit-content',
        },
        dark: {
          headerBackgroundColor: Colors.dark,
          backgroundColor: Colors.dark,
          headerColor: Colors.brand,
          descriptionColor: Colors.brand,
          backdropBackgroundColor: 'rgba(0,0,0,.2)',
        },
        wide: {
          width: '700px',
        },
      },
    },
    viewFile: {
      backgroundColor: Colors.white,
      borderRadius: '12px',
      boxShadow: '0 6px 23px 0 rgba(229,233,234,0.45)',
      zIndex: 99999,
      backdropBackgroundColor: 'rgba(0,0,0,.4)',
    },
  
  },
};
export default theme;
