import React from 'react';

import history from '../../history';

import { Button } from './backButton.styled';


const BackButton = props => (
    <Button onClick={props.onClick ? props.onClick : () => history.goBack ()} />
  );

export default BackButton;
