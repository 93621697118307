import * as R from 'ramda';
import { Storage } from 'aws-amplify';
import { gql } from '@apollo/client';

import { apolloClient } from '../services';

import { IndexedDB } from './indexed-db';
import CPTsOfflineDB, { CPTS_OFFLINE_DB } from './cpts-offline-db';


export const checkDBExists = async () => {
  const result = await new IndexedDB (CPTS_OFFLINE_DB.name, CPTS_OFFLINE_DB.objectStoreName).get (CPTS_OFFLINE_DB.dataId);

  return {
    exists: Boolean (result),
  };
};

export const setupCPTsOffline = async () => {
  const { exists } = await checkDBExists ();
  if (R.not (exists)) {
    const dbFile = await Storage.get ('tintin_cpts_offline_2021_v2.db', {
      download: true,
      bucket: 'tintin-offline',
      region: 'us-east-1',
    });

    const arrayBuffer = await dbFile.Body.arrayBuffer ();
    return Promise.resolve (arrayBuffer);
  }
  return Promise.resolve (false);
};

export const fetchCPTsOffline = async (term = '') => {
  try {
    const dbFile = await setupCPTsOffline ();
    const db = new CPTsOfflineDB (dbFile);
    const params = {};
    let sql = 'SELECT * FROM cpt';
    await db.openDatabase ();
    if (term.length) {
      params[':term'] =  `${term}*`;
      sql = 'SELECT * FROM cpt WHERE cpt MATCH :term';
    }
    const result = await db.runSQL (sql, params);
    return result;
  } catch (err) {
    // eslint-disable-next-line
    console.error ('fetchCPTsOffline', err);
    // eslint-disable-next-line
    return null;
  }
};

export const fetchCPTsBlocksOffline = async (term = '') => {
  try {
    const dbFile = await setupCPTsOffline ();
    const db = new CPTsOfflineDB (dbFile);
    const params = {};
    let sql = 'SELECT * FROM cpt WHERE blockType = 1';
    await db.openDatabase ();
    if (term.length) {
      params[':term'] =  `${term}*`;
      sql = 'SELECT * FROM cpt WHERE blockType = 1 AND cpt MATCH :term';
    }
    const result = await db.runSQL (sql, params);
    return result;
  } catch (err) {
    // eslint-disable-next-line
    console.error ('fetchCPTsOffline', err);
    // eslint-disable-next-line
    return null;
  }
};


const listCPTsQuery = gql`
query listCPTs($term: String) {
  listCPTCodes(term: $term) {
    id
    procedureCode
    procedureDescriptor
    anesthesiaCode
    anesthesiaDescriptor
  }
}
`;

export const fetchCPTsOnline = async (term = '') => {
  const { data: { listCPTCodes = [] } = {} } = await apolloClient.query ({
    query: listCPTsQuery,
    variables: {
      term,
    },
  });

  return listCPTCodes;
};

export const fetchCPTs = async (term = '') => await fetchCPTsOffline (term) || await fetchCPTsOnline (term);
export const fetchCPTsBlocks = async (term = '') => await fetchCPTsBlocksOffline (term) || await fetchCPTsOnline (term);
