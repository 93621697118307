import React from 'react';
import { Box, Flex } from 'reflexbox';


export const MAX_CONTAINER_WIDTH = 1440;

const Container = ({ children, ...props }) => (
  <Flex width={1} maxWidth={MAX_CONTAINER_WIDTH} mx="auto">
    <Box width={1} {...props}>
      {children}
    </Box>
  </Flex>
);

export default Container;
