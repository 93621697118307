import styled, { css } from 'styled-components/macro';
import { Card } from '../card';

export const typography = css`
font-weight: 400;
`;

export const heading = css`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const text = css`
  font-weight: 400;
`;

export const Heading1 = styled.h1`
  ${typography}
  ${heading}
`;

export const Heading2 = styled.h2`
  ${typography}
  ${heading}
`;

export const Heading3 = styled.h3`
  ${typography}
  ${heading}
`;

export const Heading4 = styled.h4`
  ${typography}
  ${heading}
`;

export const Heading5 = styled.h5`
  ${typography}
  ${heading}
  color: ${({ color }) => color };
  padding-left: ${({ paddingLeft }) => paddingLeft } ;
`;

export const Heading6 = styled.h6`
  ${typography}
  ${heading}
`;

export const Text = styled.p`
  ${typography}
  ${text}
`;

export const Span = styled.span`
  ${typography}
  ${text}
`;


export const DetailsCardStyled = styled (Card)`
  min-height: 100px;
`;

export const LabelStyled = styled.div`
  color: ${({ color, theme }) => color ?? theme.colors.lightBlue};
  font-size: ${({ fontSize }) => fontSize ?? '0.8rem'};
  margin-left: ${({ marginLeft }) => marginLeft ?? '0px'};
`;

export const ValueStyled = styled.div`
  color: ${({ color, theme }) => color ?? theme.colors.tertiary};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  font-weight: 500;
`;

export const PageTitle = styled.h2`
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  font-weight: 400;
  margin: 0 15px 0 0;
`;
