// Used via Metrics.baseMargin
const metrics = {
  marginHorizontal: 10,
  marginVertical: 10,
  section: 25,
  baseMargin: 10,
  doubleBaseMargin: 20,
  smallMargin: 5,
  doubleSection: 50,
  horizontalLineHeight: 1,
  searchBarHeight: 30,
  navBarHeight: 80,
  borderWidthThin: 1,
  borderWidth: 2,
  borderWidthLarge: 3,
  buttonRadius: '3rem',
  inputRadius: '8px',
  tableRadius: '30px',
  checkboxRadius: '5px',
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50,
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    logo: 200,
  },
};

export default metrics;
