import React from 'react';
import styled from 'styled-components/macro';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn, AmplifySignUp, AmplifyForgotPassword } from '@aws-amplify/ui-react';


const StyledAmplifyAuthenticator = styled (AmplifyAuthenticator)`
  && {
    align-items: center;
    background: ${props => props.theme.colors.brand};
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
  }
`;

const AuthProvider = ({ children, user }) =>
   (
    <StyledAmplifyAuthenticator usernameAlias="email">
      <AmplifySignOut />
      <AmplifySignIn
        usernameAlias="email"
        headerText="Welcome To Clomr"
        slot="sign-in"
      />
      <AmplifySignUp
        usernameAlias="email"
        headerText="Join Clomr"
        slot="sign-up"
        formFields={[
          {
            type: 'given_name',
            label: 'First Name *',
            placeholder: 'Enter your first name',
            required: true,
          },
          {
            type: 'family_name',
            label: 'Last Name *',
            placeholder: 'Enter yout last name',
            required: true,
          },
          {
            type: 'email',
            label: 'Email *',
            placeholder: 'yourname@example.com',
            required: true,
          },
          {
            type: 'password',
            label: 'Password *',
            placeholder: 'Enter Password',
            required: true,
          },
          {
            type: 'phone_number',
            label: 'Phone Number *',
            placeholder: 'Enter your phone number',
            required: false,
          },
        ]}
      />
      <AmplifyForgotPassword usernameAlias="email" slot="forgot-password"></AmplifyForgotPassword>
    </StyledAmplifyAuthenticator>
  );
export default AuthProvider;
