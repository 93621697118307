import styled from 'styled-components/macro';

import { FormGroup, LabelStyled } from '../form.styled';
import { ReactComponent as CheckIcon } from '../../../assets/icons/CorrectField_Check.svg';


export const CheckboxIcon = styled (CheckIcon)`
  left: 3.5px;
  opacity: 0;
  padding: 6px 0;
  position: absolute;
  transition: opacity .1s ease, transform .3s ease;
`;

export const CheckboxGroupStyled = styled (FormGroup)`
  justify-content: center;
  margin-top: 20px;
  position: relative;
  /* Base for label styling */
  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    z-index: 2;
  }
  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    cursor: pointer;
    font-size: ${({ theme }) => theme.components.checkbox.label.fontSize};
    line-height: ${({ theme }) => theme.components.checkbox.label.lineHeight};
    padding: ${({ theme }) => theme.components.checkbox.label.padding};
    position: relative;
  }

  /* checkbox aspect */
  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    background: ${({ theme }) => theme.components.checkbox.backgroundColor};
    border: ${({ theme }) => `${theme.components.checkbox.borderWidth}px solid ${theme.components.checkbox.borderColor}`};
    border-radius: ${({ theme }) => theme.components.checkbox.borderRadius};
    content: '';
    height: ${({ theme }) => theme.components.checkbox.height}px;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .1s ease, transform .3s ease;
    width: ${({ theme }) => theme.components.checkbox.height}px;
  }

  /* checked mark aspect changes */
  [type='checkbox']:checked ~ ${CheckboxIcon} {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  [type='checkbox']:checked ~ ${CheckboxIcon} {
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type='checkbox']:disabled:not(:checked) + label:before,
  [type='checkbox']:disabled:checked + label:before {
    background: ${({ theme }) => theme.components.checkbox.backgroundColorDisabled};
    border-color: ${({ theme }) => theme.components.checkbox.borderColorDisabled};
    box-shadow: none;
  }

  /* [type='checkbox']:disabled:checked + label:after {
    color: #777;
  }

  [type='checkbox']:disabled + label {
    color: #aaa;
  } */

  /* Accessibility */
  /* [type='checkbox']:checked:focus + label:before,
  [type='checkbox']:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(203, 34, 237, .2);
  } */
`;

export const CheckboxStyled = 'input';

export const CheckboxLabelStyled = LabelStyled;
