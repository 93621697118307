import { is, path, compose, map, prop, sum, isEmpty, not, isNil, assoc, head } from 'ramda';
import moment from 'moment';
import S from 'sanctuary';


export const toNumber = str =>
  typeof str === 'string' && !isNaN(parseInt(str, 10))
    ? parseInt(str, 10)
    : str;

export const toISOString = value => {
  let isoString = value;

  try {
    isoString = new Date(value).toISOString();
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }

  return isoString;
};

export const toTime = value => {
  if (value) {
    let timeString = value;
    try {
      timeString = moment(value).format('HH:mm');
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }

    return timeString;
  }

  return '';
};

export const toDate = value => {
  if (value) {
    let dateString = value;

    try {
      dateString = moment(value).format('MM/DD/YYYY');
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }

    return dateString;
  }
  return '';
};

export const toCaseDate = value => {
  let dateString = value;

  try {
    dateString = moment(value).format('MM / DD / YYYY');
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }

  return dateString;
};

export const combineDateAndTime = (date, time) => {
  const timeString = time ? time : new Date().getHours() + ':00'; // eslint-disable-line no-unneeded-ternary
  const _date = is(Date, date) ? date : new Date(date);
  const year = _date.getFullYear();
  const month = _date.getMonth() + 1; // Jan is 0, dec is 11
  const day = _date.getDate();
  const dateString = `${year}-${month}-${day}`;
  const combined = new Date(dateString + ' ' + timeString);
  return combined;
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const toPhoneNumber = phoneNumberString => {
  if (typeof phoneNumberString === 'string') {
    const cleaned = phoneNumberString?.replace(/\D/g, '');
    const match = cleaned?.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  }
  return '';
};

export const toLabelValue = (arr = []) => arr.map(item => ({ value: item, label: item }));

export const removeEmpty = (arr = []) => arr.filter(item => Boolean(item));

export const maskPhoneNumber = text => {
  if (typeof text !== 'undefined' && text) {
    const phone = S.pipe ([
      s => s.replace (/\D/g, ''),
      s => s.substring (0, 10),
      s => {
        if (s.length < 3) {
          return s;
        } else if (s.length < 4 && s.length > 0) {
          return `(${s}`;
        } else if (s.length < 7) {
          return `(${s.substring (0, 3)}) ${s.substring (3, 6)}`;
        } else {
          return `(${s.substring (0, 3)}) ${s.substring (3, 6)} - ${s.substring (6, 10)}`;
        }
      },
    ]) (text);
    return phone;
  } else {
    return '';
  }
};

export const unMaskPhoneNumber = phoneNumberString => {
  if (phoneNumberString) {
    const cleaned = phoneNumberString?.replace(/\D/g, '');
    const match = cleaned?.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match['input'];
    }
  }
  return phoneNumberString;

};

export const getMetricValue = data => path(['getCaseMetrics', 'metrics', 0, 'values', 0, 'metric'])(data);

export const getMetricValues = data => path(['getCaseMetrics', 'metrics', 0, 'values'])(data);

export const sumMetricValues = data => compose(sum(), map(prop('metric')))(getMetricValues(data));

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatProviderType(text) {
  if (text) {
    const providerType = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    return `${providerType.substr(0, 1).toUpperCase()}${providerType.substr(1)}`;
  }
  return '';
}


export function getAge(birthdayDateString) {
  if (not(is(String, birthdayDateString))) {
    return null; // eslint-disable-line
  }

  const birthdayDate = new Date(birthdayDateString);

  if (isValidDate(birthdayDate)) {
    const diff = new Date(Date.now() - birthdayDate.getTime());
    return Math.abs(diff.getUTCFullYear() - 1970);
  }
  return null; // eslint-disable-line
}

export function getGenderCode(gender) {
  if (isEmpty(gender) || isNil(gender)) {
    return '';
  }
  return gender.substr(0, 1).toUpperCase();
}

export function getFileName(file, seperater) {
  const fileName = file.split(seperater)[1];
  return fileName.substring(0, fileName.indexOf('?', 0));
}

export function getKeyFromUrl(key) {
  const fileName = key.split('/public/')[1];
  return fileName.substring(0, fileName.indexOf('?', 0));
}

export const generateName = profile => {
  if (!profile) {
    return '';
  }
  return `${profile.firstName ? profile.firstName : ''}${profile.firstName && profile.lastName ? ' ' : ''}${profile.lastName ? profile.lastName : ''}`;
};

export function getMessageSenderText(owner) {
  if (!owner) {
    return '';
  }
  return owner.firstName.substr(0, 1).toUpperCase() + owner.lastName.substr(0, 1).toUpperCase();
}

export const formatCaseStatus = status => {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'inProgress':
      return 'Open / Pending';
    case 'rejected':
      return 'Needs Review';
    case 'billerConfirmed':
      return 'Confirmed By Biller';
    case 'approved':
      return 'Approved';
    default:
      return ''
  }
};

export function getTimeInMinutes(time) {
  if (time) {
    const date = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const _time = new Date(dateString + ' ' + time);
    const hours = Number(moment(_time).utc().format('HH')) * 60;
    const totalMinutes = Number(moment(_time).utc().format('mm')) + hours;
    return totalMinutes;
  }
  return 0;
}

export const mapSitesData = map(item => compose(
  assoc('value', item.id),
  assoc('label', item.fullName),
)(item));

export const mapUserProfileData = map(item => compose(
  assoc('value', item.id),
  assoc('label', `${item.firstName} ${item.lastName}`),
)(item));

export const mapInsuranceData = map(item => compose(
  assoc('value', item.id),
  assoc('label', item.name),
)(item));

export const mapPatientsData = map(item => compose(
  assoc('value', item.id),
  assoc('label', `${item.name?.use} ${item.name?.family}`),
)(item));

export const getCreateCaseMaxDate = () => {
  const date = new Date();
  return new Date(date.setDate(date.getDate() + 30));
}

export const getCreateCaseMinDate = () => {
  const date = new Date();
  return new Date(date.setDate(date.getDate() - 180));
}

export const getCaseFileName = (patient, date, provider, file) => {
  const patientName = patient && patient.name ? `${patient?.name?.use?.substr(0, 1)}${patient?.name?.family?.substr(0, 1)}` : '';
  const caseDate = date ? moment (date).format ('MM-DD-YYYY') : '';
  const providerName = provider ? `${provider.firstName}-${provider.lastName}` : '';
  return `${patientName}_${caseDate}_${providerName}_${file.name}`;
};


export const formatUserGroupName = group => {
  switch (group) {
    case 'globaladmin':
      return 'Tenant Admin';
    case 'biller':
      return 'Biller';
    case 'officeadmin':
      return 'Office Admin';
    case 'superadmin':
      return 'Super Admin';
    case 'provider':
      return 'Provider';
    default:
      return ''
  }
};

export const getErrorMessage = (item, errors) => {
  if (not(isNil(errors))) {
    return prop(item, errors) ? compose(head, prop(item, errors)) : '';
  }
  return '';
}
