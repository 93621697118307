import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-icon.svg';

import { getComponentStyle } from '../helpers';

export const FileWrapper = styled.div`
  ${({ theme }) => {
    const viewFileStyle = getComponentStyle ('viewFile', theme);

    return css`
      align-items: center;
      background-color: ${viewFileStyle.backgroundColor};
      border-radius: ${viewFileStyle.borderRadius};
      box-shadow: ${viewFileStyle.boxShadow};
      flex-direction: column;
      inset: 0;
      margin: 20px auto;
      max-width: 90%;
      position: fixed;
      width: 100%;
      z-index: ${viewFileStyle.zIndex};
      /* stylelint-disable-next-line */
      ${({ show }) => Boolean (show) ? css`
        display: flex;
      ` : css`
        display: none;
      `}
    `;
  }};
`;

export const FileBackDrop = styled.div`
  ${({ theme }) => {
    const viewFileStyle = getComponentStyle ('viewFile', theme);

    return css`
      background-color: ${viewFileStyle.backdropBackgroundColor};
      inset: 0;
      position: fixed;
      z-index: ${viewFileStyle.zIndex - 1};
    `;
  }};
`;

export const CloseButton = styled.div.attrs ({ children: <CloseIcon /> })`
  background-color: transparent;
  bottom: 0;
  cursor: pointer;
  margin: 0;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 0;
  transition: .3s ease opacity;
  z-index: 100;
  &, svg {
    height: 45px;
  }

  svg {
    stroke: ${({ theme }) => theme.colors.grey};
    width: 25px;
  }

  svg:hover {
    transition: .3s ease opacity;
    stroke: ${({ theme }) => theme.colors.brand};
  }
`;

export const DownloadButton = styled.div.attrs ({ children: <DownloadIcon /> })`
    background-color: transparent;
    bottom: 0;
    cursor: pointer;
    margin: 0;
    padding: 5px;
    position: absolute;
    right: 70px;
    top: 0;
    transition: .3s ease opacity;
    z-index: 100;
    &, svg {
      height: 45px;
    }

    svg {
      fill: ${({ theme }) => theme.colors.grey};
      width: 30px;
    }
    
    svg:hover {
      transition: .3s ease opacity;
      fill: ${({ theme }) => theme.colors.brand};
    }
`;

export const Image = styled.div`
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 20px auto;
  width: 100%;
  height: calc(100%);  
`;



export const PDFWrapper = styled.div`
  max-width: 100%;
  position: relative;
  overflow: hidden;
`;

export const PageControls = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: #fff;
  opacity: 0;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  ${PDFWrapper}:hover & {
    opacity: 1;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 150px;
  align-content: space-between;
  padding: 5px;
  justify-content: center;
`;

export const Button = styled.button`
  display: block;
  background: #fff;
  border: 0;
  cursor: pointer;
  font-size: 25px;
  width: 44px;
  height: 44px;
  margin-top: -3px;
`;
