import React from 'react';

import { MessageStyled } from './messageStyled';


const SuccessMessage = ({ children }) => (
    <MessageStyled>
      {children}
    </MessageStyled>
  );

export default SuccessMessage;
