import { createGlobalStyle } from 'styled-components/macro';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const BaseStyles = createGlobalStyle`
  :root {
    --amplify-text-xxs: 0.75rem;
    --amplify-text-xs: 0.81rem;
    --amplify-text-sm: 0.83rem;
    --amplify-text-md: 1rem;
    --amplify-text-lg: 1.5rem;
    --amplify-text-xl: 2rem;
    --amplify-text-xxl: 2.5rem;
  }
`;

export default BaseStyles;
