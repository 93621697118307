import { gql } from '@apollo/client';


export const createCaseMutation = gql`
mutation createCase($caseLog: CaseInput!) {
  createCase(caseLog: $caseLog) {
    id
  }
}
`;

export const updateCaseMutation = gql`
mutation updateCase($id: ObjectID!, $caseLog: CaseInput!) {
  updateCase(id: $id, caseLog: $caseLog) {
    id
  }
}
`;

export const updateCaseStatusMutation = gql`
mutation updateCaseStatus($id: ObjectID!, $status: CaseStatus!) {
  updateCaseStatus(id: $id, status: $status) {
    id
  }
}
`;


export const requestExportCasesMutation = gql`
mutation requestExportCases($filter: CaseFilterInput, $options: CaseExportOptionsInput!) {
  requestExportCases(filter: $filter, options: $options) {
    id
  }
}
`;


export const updateCaseLineItemsMutation = gql`
mutation updateCaseLineItems($input: [CaseLineItemInput]!) {
  updateCaseLineItems(input: $input) {
    id
  }
}
`;
export const createMessageMutation = gql`
  mutation createMessage($caseId: ObjectID!, $message: String!) {
    createMessage(caseId: $caseId, message: $message)
  }
`;

export const MIGRATE_FILES_TO_CASE = gql`
  mutation migrate_files_to_case ($id: ObjectID!, $items: [String]!){
    migrateFilesToCase (id: $id, items:$items) {
      id
    }
  }
`;
