import React, { useMemo } from 'react';
import { AuthState } from '@aws-amplify/ui-components';

import history from '../../../history';
import { Redirect } from '../../link';
import { useAuth } from '../../../utils/auth';


const AuthProvider = ({ children }) => {
  const pathname = history?.location?.pathname;
  const page = useMemo (() => getPage (pathname), [pathname]);
  const useAuthState = useAuth ();

  return (
    <React.Fragment>
      {
        useAuthState.authState === AuthState.Loading
        ? <div></div> // TODO: handle loading
        : useAuthState.authState === AuthState.SignedIn
          ? children
        : <Redirect to={`/${page}`} />
      }
    </React.Fragment>
  );
};

const getPage = (url = '') => {
  if (url.includes ('signin')) return 'signin';
  else if (url.includes ('forgot-password')) return 'forgot-password';
  else return 'signin';
};

export default AuthProvider;
