import React from 'react';

import { FileInput } from '../FileInput';
import Item from './item';
import { Column } from './fileUpload.styled';



export const FileUpload = ({
  name,
  label,
  onSelect,
  items = [],
  item = '',
  singleFile = false,
  onRemove,
  accept = 'image/*',
}) => {
  return (
    <Column marginBottom={10}>
      {!singleFile && items &&
        items.map(item => (
          <Item
            key={`item-${item.name}`}
            name={item.name}
            type={item.type}
            onRemove={() => onRemove(item)}
          />
        ))}
        {singleFile && item &&
          <Item
            key={`item-${item.name}`}
            name={item.name}
            type={item.type}
            onRemove={() => onRemove(item)}
          />
        }
      <FileInput
        name={name}
        label={label}
        accept={accept}
        onChange={({ target: { files } = {} }) => onSelect(files[0])}
      />
    </Column>
  );
};
