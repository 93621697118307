import React from 'react';

import { SpinnerWrapper, Logo, LoaderWrapper } from './loaders.styled';


const Spinner = ({
  $size,
  $borderSize,
  $margin,
  $color,
  className,
}) => (
  <LoaderWrapper>
    <Logo src="/app-icon.png" />
    <SpinnerWrapper
      className={className}
      $size={$size}
      $color={$color}
      $margin={$margin}
      $borderSize={$borderSize}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </SpinnerWrapper>
  </LoaderWrapper>
);

export default Spinner;
