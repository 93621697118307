import styled, { css } from 'styled-components/macro';

import { spacing } from '../../styles';


export const ButtonStyled = styled.button`
  border: 0;
  border-radius: ${({ theme }) => theme.components.primaryButton.borderRadius};
  box-shadow: none;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  display: block;
  font-size: 1rem;
  line-height: ${({ icon }) => icon ? '17px' : '15px'};;
  margin: ${({ margin }) => margin ? margin : '0 10px'};
  max-height: 50px;
  ${spacing}
  ${({ active, bordered, theme, disabled, width, brand, icon }) => {
    const mainColor = disabled ? theme.colors.greyBrand : theme.components.primaryButton.color;
    const subColor = disabled ? theme.colors.greyBrand : theme.components.primaryButton.backgroundColor;
    const textColor = disabled ? theme.colors.secondaryGrey : theme.colors.secondaryGrey;
    const activeColor = icon ? theme.components.primaryButton.activeBulkBorderColor : theme.components.primaryButton.activeBorderColor;
    const activeTextColor = icon ?theme.components.primaryButton.activeBulkColor : theme.components.primaryButton.activeColor;

    return bordered ?
      css`
        background-color: ${active ? theme.components.primaryButton.activeBackgroundColor : theme.components.primaryButton.backgroundColor};
        border: 2px solid ${active ? activeColor : mainColor};
        color: ${active ? activeTextColor : mainColor};
        width: ${width ?? '300px'};
        ${!disabled &&
        css`
          &:hover {
            background-color: ${subColor};
            border-color: ${activeColor};
            color: ${activeTextColor};
            transition: .3s ease background-color, .3s ease color, .3s ease border-color;
          }
        `}
      ` : brand ?
        css`
        background-color: ${!disabled ? theme.colors.brand : theme.colors.greyBrand};
        color: ${theme.colors.white};
        width: ${width ?? '100%'};
        ${!disabled &&
          css`
            &:hover {
              background-color: ${theme.colors.activeBackgroundColor};
              transition: .3s ease background-color, .3s ease color, .3s ease border-color;
            }
        `}
      ` :
        css`
        background-color: ${subColor};
        color: ${textColor};
        width: ${width ?? '300px'};
        ${!disabled &&
            css`
            &:hover {
              background-color: ${theme.colors.activeBackgroundColor};
              transition: .3s ease background-color, .3s ease color, .3s ease border-color;
            }
        `}
      `;
  }}
  outline: none;
  padding: .9rem .5rem;
  position: relative;
  transition: .3s ease background-color, .3s ease color, .3s ease border-color;
  ${({ theme }) => css`
  @media ${theme.device.wide} {
    padding: ${({ icon }) => icon && '.8rem .5rem'};
  `}
`;
