import React, { useMemo } from 'react';
import InputMask from 'react-input-mask';

import { ErrorStyled, LabelStyled } from '../form.styled';

import { getIcon, typeBasedIcon } from './input.config';
import { InputStyled, InputGroupStyled, InputWrapperStyled } from './input.styled';


const Input = ({
  name,
  label,
  error,
  icon: _icon,
  tag = 'input',
  noVerify = false,
  ...rest
}) => {
  const icon = _icon || typeBasedIcon (rest.type);
  const Icon = useMemo (() => getIcon (icon), [icon]);
  const CheckIcon = useMemo (() => getIcon ('check'), []);
  const CloseIcon = useMemo (() => getIcon ('close'), []);
  const invalid = useMemo (() => Boolean (error), [error]);
  const valid = useMemo (() => !invalid && Boolean (rest.value), [invalid, rest.value]);

  return (
    <InputGroupStyled invalid={Boolean (error)}>
      {label &&
        <LabelStyled htmlFor={name}>{label}</LabelStyled>}
      <InputWrapperStyled>
        {icon &&
          <Icon
            color={invalid ? 'error' : noVerify ? '' : valid ? 'green' : ''}
          />}
        <InputStyled
          id={name}
          as={rest.mask ? InputMask : tag}
          name={name}
          invalid={invalid}
          aria-invalid={invalid}
          valid={noVerify ? false : valid}
          withIcon={Boolean (icon)}
          {...rest}
        />
        {(!noVerify && valid) &&
          <CheckIcon right stroke="green" />}
        {invalid &&
          <CloseIcon right stroke="error" />}
      </InputWrapperStyled>
      {error &&
        <ErrorStyled>{error}</ErrorStyled>}
    </InputGroupStyled>
  );
};

export default Input;
