import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Heading6 } from '../../typography';

import { UploadItem, Column, CloseButton, CloseButtonWrapper } from './fileUpload.styled';


export default function Item (props) {
  const theme = useContext (ThemeContext);

  return (
    <UploadItem padding={14}>
      <Column>
        <Heading6 color={theme.darkText}>{props.name.substring (0, 50)}</Heading6>
      </Column>
      <CloseButtonWrapper onClick={props.onRemove}>
        <CloseButton />
      </CloseButtonWrapper>
    </UploadItem>
  );
}
