import React from 'react';
import { Alert } from '../alert';
import { CloseButton, ModalBody, ModalHeader, ModalWrapper, ModalBackDrop, ModalDescription, ModalAlert } from './Styles';


export const Modal = ({
  show = false,
  setShow,
  title = '',
  description = '',
  closable = true,
  bodyComponent: Body = ModalBody,
  headerComponent: Header = ModalHeader,
  backDropComponent: BackDrop = ModalBackDrop,
  variant,
  children,
  onClose,
  error = '',
}) => {
  const onCloseModal = () => {
    setShow (false);
    onClose && onClose ();
  }
  return <>
    <ModalWrapper data-testid='modal' show={show} variant={variant}>
      {setShow && closable &&
        <CloseButton onClick={onCloseModal} />}
      {title &&
        <Header variant={variant}>
          {title}
        </Header>}
      {error &&
        <ModalAlert>
          <Alert>{error}</Alert>
        </ModalAlert>}
      {description &&
        <ModalDescription variant={variant}>
          {description}
        </ModalDescription>}
      {children &&
        <Body variant={variant}>{children}</Body>}
    </ModalWrapper>
    {show && setShow &&
      <BackDrop variant={variant} onClick={() => setShow(false)} />}
  </>
}
