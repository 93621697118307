import React, { useState } from 'react';
import { Box, Flex } from 'reflexbox';
import { not } from 'ramda';
// import { Footer } from '../../footer';

import { Navbar } from '../../navbar';
import { Sidebar } from '../../sidebar';
import { ReactComponent as OpenMenuIcon } from '../../../assets/images/open-menu-icon.svg';

import { AppLayoutStyled, PageContainer, OpenMenuButton } from './appLayout.styled';
import Container from './Container';


const AppLayout = ({ children, userGroups, userName }) => {
  const [showMenu, setShowMenu] = useState (false);
  return (
    <AppLayoutStyled>
      <Flex>
        <Box width={[0, 3 / 24]} p={0}>
          <Sidebar userGroups={userGroups} active={showMenu} setShowMenu={setShowMenu} />
        </Box>
        <PageContainer width={[1, 21 / 24]} m={15} p={15}>
          <OpenMenuButton onClick={() => setShowMenu (not)}>
            <OpenMenuIcon />
          </OpenMenuButton>
          <Navbar userGroups={userGroups} userName={userName} />
          <Container>
            {children}
          </Container>
        </PageContainer>
      </Flex>
      {/* <Footer userGroups={userGroups} /> */}
    </AppLayoutStyled>
  );
};

export default AppLayout;
