import styled from 'styled-components/macro';

import { FormGroup, LabelStyled } from '../form.styled';


export const RadioWrapperStyled = styled (FormGroup)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ invalid, theme }) => invalid && `
    border-color: ${theme.colors.error};
  `}
`;

export const RadioLabelStyled = styled (LabelStyled)`
  ${({ invalid, theme }) => invalid && `
    color: ${theme.colors.error};
  `}
`;




export const RadioLabelWrapper = styled (FormGroup)`
  &:not(:last-child) {
    margin-bottom: 0;
  }
`;

export const RadioGroupStyled = styled (FormGroup)`
  position: relative;
  &:not(:last-child) {
    margin: 0;
  }

  /* Base for label styling */
  [type='radio']:not(:checked),
  [type='radio']:checked {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  [type='radio']:not(:checked) + label,
  [type='radio']:checked + label {
    color: ${({ theme }) => theme.components.radio.label.fontColor};
    cursor: pointer;
    font-size: ${({ theme }) => theme.components.radio.label.fontSize};
    font-weight: ${({ theme }) => theme.components.radio.label.fontWeight};
    line-height: ${({ theme }) => theme.components.radio.label.lineHeight};
    padding: ${({ theme }) => theme.components.radio.label.padding};
  }

  /* radio aspect */
  [type='radio']:not(:checked) + label:before,
  [type='radio']:checked + label:before {
    background: ${({ theme }) => theme.components.radio.backgroundColor};
    border-radius: ${({ theme }) => theme.components.radio.borderRadius};
    content: '';
    height: ${({ theme }) => theme.components.radio.height}px;
    left: 0;
    position: absolute;
    top: 0;
    transition: border-color .2s ease;
    width: ${({ theme }) => theme.components.radio.height}px;
  }
  [type='radio']:not(:checked) + label:before {
    border: ${({ theme }) => theme.components.radio.borderWidth}px solid ${({ theme }) => theme.components.radio.borderColor};
  }

  [type='radio']:checked + label:before {
    border: ${({ theme }) => theme.components.radio.borderWidth}px solid ${({ theme }) => theme.components.radio.borderColorFocus};
  }

  /* checked mark aspect */
  [type='radio']:not(:checked) + label:after,
  [type='radio']:checked + label:after {
    background: ${({ theme }) => theme.components.radio.backgroundColorFocus};
    border-radius: ${({ theme }) => theme.components.radio.borderRadius};
    content: '';
    height: ${({ theme }) => theme.components.radio.innerCircleHeight}px;
    left: ${({ theme }) => theme.components.radio.height - theme.components.radio.innerCircleHeight - theme.components.radio.borderWidth}px;
    position: absolute;
    top: ${({ theme }) => theme.components.radio.height - theme.components.radio.innerCircleHeight - theme.components.radio.borderWidth}px;
    transition: border-color .2s ease;
    width: ${({ theme }) => theme.components.radio.innerCircleHeight}px;
  }

  /* checked mark aspect changes */
  [type='radio']:not(:checked) + label:after {
    opacity: 0;
  }

  [type='radio']:checked + label:after {
    opacity: 1;
  }

  /* Disabled radio */
  [type='radio']:disabled:not(:checked) + label:before,
  [type='radio']:disabled:checked + label:before {
    background: ${({ theme }) => theme.components.radio.backgroundColorDisabled};
    border-color: ${({ theme }) => theme.components.radio.borderColorDisabled};
    box-shadow: none;
  }
`;

export const RadioStyled = 'input';
