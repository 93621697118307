import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getUserQuery } from './graphql';


export const UserContext = createContext ();


const UserContextProvider = props => {
  const [user, setUser] = useState (null); // eslint-disable-line
  const { data: userData } = useQuery (getUserQuery, { fetchPolicy: 'cache-and-network'});

  useEffect (() => {
    if (userData && userData.getAuthenticatedUserDetails) {
      setUser (userData.getAuthenticatedUserDetails);
    }
  }, [userData]);
  return (
    <UserContext.Provider value={{ 'user': user }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
