import { createGlobalStyle } from 'styled-components/macro';
import { darken } from 'polished';


export default createGlobalStyle`

  @font-face {
      font-family: 'ProximaNova';
      font-weight: 200;
      src:local('ProximaNova'), url('/fonts/ProximaNova-Thin.otf') format('opentype');
      font-style: thin;
  }

  @font-face {
      font-family: 'ProximaNova';
      font-weight: 400;
      src: local('ProximaNova'), url('/fonts/ProximaNova-Regular.otf') format('opentype');
      font-style: base;
  }

  @font-face {
      font-family: 'ProximaNova';
      font-weight: 500;
      src: local('ProximaNova'), url('/fonts/ProximaNova-Bold.otf') format('opentype');
      font-style: semiBold;
  }

  @font-face {
      font-family: 'ProximaNova';
      font-weight: 600;
      src: local('ProximaNova'), url('/fonts/ProximaNova-Extrabold.otf') format('opentype');
      font-style: bold;
  }

  @font-face {
    font-family: 'ProximaNova';
    font-weight: 800;
    src: local('ProximaNova'), url('/fonts/ProximaNova-Black.otf') format('opentype');
    font-style: black;
  }

  @media print {
    *,
    *::before,
    *::after {
      background: transparent !important;
      box-shadow: none !important;
      color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]::after {
      content: ' (' attr(href) ')';
    }

    abbr[title]::after {
      content: ' (' attr(title) ')';
    }

    /*
    * Don't show links that are fragment identifiers,
    * or use the 'javascript:' pseudo protocol
    */

    a[href^='#']::after,
    a[href^='javascript:']::after {
      content: '';
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    /*
    * Printing Tables:
    * http://css-discuss.incutio.com/wiki/Printing_Tables
    */

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }

  html {
    color: ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.fonts.family};
    font-size: ${props => props.theme.fonts.style.normal.fontSize};
    font-weight: ${props => props.theme.fonts.style.normal.fontWeight};
    line-height: 1.375; /* ~22px */
  }

  @media ${props => props.theme.device.wide} {
    html {
      font-size: ${props => props.theme.fonts.style.large.fontSize};
    }
  } 

  body {
    margin: 0;
  }

  body::-webkit-scrollbar {
    width: 10px;
  }
 
  body::-webkit-scrollbar-track {
    background-color: ${props => props.theme.colors.dark};
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.brand};
    border-radius: 20px;      
    border: 3px solid ${props => props.theme.colors.brand};
  }

  a, button, input, select {
    font-family: ${props => props.theme.fonts.family};
  }

  a {
    color: ${props => props.theme.colors.brand};
    text-decoration: none;
    &:hover {
      color: ${props => darken(0.2)(props.theme.colors.dark)};
    }
  }

  h1 {
    font-size: ${({ theme }) => theme.fonts.style.h1.fontSize};
    font-weight: ${({ theme }) => theme.fonts.style.h1.fontWeight};
  }
  h2 {
    font-size: ${({ theme }) => theme.fonts.style.h2.fontSize};
    font-weight: ${({ theme }) => theme.fonts.style.h2.fontWeight};
  }
  h3 {
    font-size: ${({ theme }) => theme.fonts.style.h3.fontSize};
    font-weight: ${({ theme }) => theme.fonts.style.h3.fontWeight};
  }
  h4 {
    font-size: ${({ theme }) => theme.fonts.style.h4.fontSize};
    font-weight: ${({ theme }) => theme.fonts.style.h4.fontWeight};
  }
  h5 {
    font-size: ${({ theme }) => theme.fonts.style.h5.fontSize};
    font-weight: ${({ theme }) => theme.fonts.style.h5.fontWeight};
  }
  h6 {
    font-size: ${({ theme }) => theme.fonts.style.h6.fontSize};
    font-weight: ${({ theme }) => theme.fonts.style.h6.fontWeight};
  }

  /*
  * Remove text-shadow in selection highlight:
  * https://twitter.com/miketaylr/status/12228805301
  *
  * These selection rule sets have to be separate.
  * Customize the background color to match your design.
  */

  ::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  ::selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  /*
  * A better looking default horizontal rule
  */

  hr {
    border: 0;
    border-top: 1px solid #ccc;
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  */

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  /*
  * Remove default fieldset styles.
  */

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  /*
  * Allow only vertical resizing of textareas.
  */

  textarea {
    resize: vertical;
  }

  /**
  * Remove input icons
  */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance:none; /* stylelint-disable-line */
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none; /* stylelint-disable-line */
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield; /* stylelint-disable-line */
  }

  input::-webkit-inner-spin-button,
  input::-webkit-calendar-picker-indicator {
      opacity: 0;
      -webkit-appearance: none; /* stylelint-disable-line */
  }

  /*
  * Browser upgrade prompt
  * ========================================================================== */

  :global(.browserupgrade) {
    background: #ccc;
    color: #000;
    margin: 0.2em 0;
    padding: 0.2em 0;
  }

  /*
  * Print styles
  * Inlined to avoid the additional HTTP request:
  * http://www.phpied.com/delay-loading-your-print-css/
  * ========================================================================== */
`;
