import React, { useState } from 'react';
import { not } from 'ramda';
import { formatUserGroupName } from '../../utils';
import { NavStyled, Dropdown, Icon, Item, NavHeader, PleaceholderIcon, UserName, UserGroup, UserInfo, Avatar, AvatarImage } from './dropdown-menu.styled';


const Menu = ({ children, name, group, avatar }) => {
  const [open, setOpen] = useState(false);
  return (<NavStyled>
    <NavHeader className="navbar-nav">
      {avatar ? <Avatar><AvatarImage src={avatar}/></Avatar> : <PleaceholderIcon />}
      <UserInfo onClick={() => setOpen(not)}>
        <div>
          <UserName>{name}</UserName>
          <UserGroup>{formatUserGroupName(group)}</UserGroup>
        </div>
        <Icon open={open} />
      </UserInfo>
    </NavHeader>
    {open && <Dropdown>
      {children}
    </Dropdown>
    }
  </NavStyled>
  );
};


const MenuItem = ({ children }) => (
  <Item>
    {children}
  </Item>
);

export {
  Menu,
  MenuItem,
};
