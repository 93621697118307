import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';
import { fetch } from 'cross-fetch';


const httpLink = new HttpLink ({ uri: process.env.REACT_APP_API_URL, fetch });

const authLink = setContext (async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { idToken: { jwtToken: token } = {} } = await Auth.currentSession ();
  // eslint-disable-next-line no-console
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloClient = new ApolloClient ({
  link: authLink.concat (httpLink),
  cache: new InMemoryCache (),
});
