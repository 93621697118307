import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { AuthState } from '@aws-amplify/ui-components';
import Amplify from '@aws-amplify/core';
import { ThemeProvider } from 'styled-components/macro';
import { ApolloProvider } from '@apollo/client';

import getAwsConfig from './aws-config';
import { theme, NormalizeStyles, BaseStyles } from './styles';
import { AppLayout, AuthProvider, NotAllowed } from './components';
import { apolloClient } from './services';
import { hasAnyGroup, USER_GROUPS, useAuth } from './utils';
import UserContextProvider from './context/user-context';

const App = ({ children, isPublic }) => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const useAuthState = useAuth();

  useEffect(() => {
    setAuthState(useAuthState.authState);
    setUser(useAuthState.user);
  }, [useAuthState]);

  useEffect(() => {
    const awsConfig = getAwsConfig();
    Amplify.configure(awsConfig);
  }, []);

  const userToken = user?.signInUserSession?.idToken?.payload ?? {};
  const userName = userToken['email'] || '';
  const userGroups = userToken['cognito:groups'] || [];
  const userTenant = userToken['custom:tenant'] || '';
  const isAllowed = hasAnyGroup(userGroups, [USER_GROUPS.superAdmin, USER_GROUPS.globalAdmin, USER_GROUPS.biller, USER_GROUPS.officeAdmin, USER_GROUPS.provider]);
  return (
    <ThemeProvider theme={theme}>
      <NormalizeStyles />
      <BaseStyles />
      <ApolloProvider client={apolloClient}>
        <UserContextProvider>
          {isPublic
            ? React.Children.only(children)
            : authState === AuthState.SignedIn && user ?
              isAllowed ?
                <AppLayout userGroups={userGroups} userName={userName}>
                  {React.cloneElement(children, { userGroups, userTenant })}
                </AppLayout>
                : <NotAllowed />
              : <AuthProvider>{React.cloneElement(children, { userGroups, userTenant })}</AuthProvider>
          }
        </UserContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

const ContextType = {
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
};

App.propTypes = {
  context: PropTypes.shape(ContextType).isRequired,
  children: PropTypes.element.isRequired,
};

export default hot(module)(App);
