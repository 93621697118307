import styled from 'styled-components/macro';


export const NavbarStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;

  padding: 1rem;
  text-align: center;

  img {
    height: 48px;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.grey};
  font-size: 1.2rem;
  font-weight: 400;
`;
