import React from 'react';

import { EllipsisWrapper, Logo, LoaderWrapper } from './loaders.styled';


const Ellipsis = ({
  $size,
  $dotSize,
  $margin,
  $left,
  $top,
  $color,
  className,
}) => (
  <LoaderWrapper>
    <Logo src="/app-icon.png" />
    <EllipsisWrapper
      className={className}
      $size={$size}
      $color={$color}
      $dotSize={$dotSize}
      $margin={$margin}
      $left={$left}
      $top={$top}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </EllipsisWrapper>
  </LoaderWrapper>
);

export default Ellipsis;
